// @mui-components
import { Box } from "@mui/material";
// @components
import CommentsSkeleton from "../../skeletons/comments-skeleton";
import DashCommentsSkeleton from "../../skeletons/dash-comments-skeleton";
import CommentRecordTemp from "./comment-record-temp";
import NoItems from "../../no-items";
// @img
import noComment from "../../../img/no-comment.png";

function CommentBody(props) {
  const { comments, isSubmitting, inDash } = props;
  // edit-comment
  const handleEditComment = (value) => {
    props.editCommentId(value);
  };

  // delete-comment
  const handleDeleteComment = (value) => {
    props.deleteCommentId(value);
  };

  return (
    <Box
      className="scroll_filtration"
      sx={{
        flexDirection: "column",
        display: "flex",
        justifyContent: "flex-start",
        alignContent: "center",
        backgroundColor: "#fff",
        overflowY: "scroll",
        overflowX: "hidden",
        height: "50vh",
        minWidth: "100%",
        maxWidth: "100%",
      }}
    >
      {isSubmitting ? (
        inDash ? (
          <DashCommentsSkeleton />
        ) : (
          <CommentsSkeleton />
        )
      ) : comments?.length !== 0 ? (
        comments?.map((comment) => {
          return (
            <CommentRecordTemp
              inDash={inDash}
              key={comment?.id}
              data={comment}
              // commentID
              editCommentId={handleEditComment}
              deleteCommentId={handleDeleteComment}
            />
          );
        })
      ) : (
        <NoItems img={noComment} msg="لا يوجد اي تعليق حاليا !" />
      )}
    </Box>
  );
}

export default CommentBody;
