//@Form
import checkout from "./form";

const {
  formField: { comment, direction, province, otherProvince, city, otherCity },
} = checkout;

const initialValues = (commentToUpdate) => {
  return {
    [comment.name]: commentToUpdate?.comment,
    [direction.name]: commentToUpdate?.direction,
    [province.name]: commentToUpdate?.province,
    [otherProvince.name]: province === "آخرى" ? commentToUpdate?.province : "",
    [city.name]: commentToUpdate?.city,
    [otherCity.name]: city === "آخرى" ? commentToUpdate?.city : "",
  };
};

export default initialValues;
