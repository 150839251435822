/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

// @API
import { File } from "../utils/fetcher-api";

// Sleeper
const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export default async function UploadImage(img, userId, dateTime) {
  try {
    const imgToSend = new FormData();
    imgToSend.append("file", img);
    imgToSend.append("user_id", userId);
    imgToSend.append("dateTime", dateTime);

    const response = await File.upload(imgToSend);
    await sleep(1000);

    if (response.code === 200 && response.status) {
      return response.data;
    }
    return "";
  } catch (error) {
    return "";
  }
}
