// @Yup
import * as Yup from "yup";
// @Form
import checkout from "./form";
// @Regular Expression
// import { PasswordRegExp } from "../../../../../../../utils/RegExp";

const {
  formField: {
    first_name,
    last_name,
    age,
    gender,
    direction,
    province,
    city,
    date_disappeared,
    type_disappeared,
    is_found,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [first_name.name]: Yup.string(),
    [last_name.name]: Yup.string(),
    [age.name]: Yup.string(),
    [gender.name]: Yup.string(),
    [direction.name]: Yup.string(),
    [province.name]: Yup.string(),
    [city.name]: Yup.string(),
    [date_disappeared.name]: Yup.string(),
    [type_disappeared.name]: Yup.string(),
    [is_found.name]: Yup.string(),
  }),
];

export default validations;
