/* eslint-disable react-hooks/exhaustive-deps */
// @react
import { useEffect, useState } from "react";
// @components
import Pagination from "../../components/pagination";
import PaperUsers from "../../components/paper-users";
import Snackbar from "../../components/Snackbar";
import AlertDialog from "../../components/alert-dialog";
// @API
import { User } from "../../utils/fetcher-api";
// @react-router-dom
import { useNavigate } from "react-router-dom";

function ListingUsers() {
  // use-@react-router-dom
  const navigate = useNavigate();
  // pagination
  const [itemPerPage, setItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [counterPages, setCounterPages] = useState(1);

  const handleItemPerPageChange = (value) => {
    setItemPerPage(value);
    setCurrentPage(1);
  };
  const handleCurrentPageChange = (value) => {
    setCurrentPage(value);
  };
  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };
  // dialog-confirmation
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState(null);

  const handleOpenDialogChange = (value) => {
    setSelectedIdToDelete(value);
    setIsOpenDialog(true);
  };

  const handleDeleteConfirmationChange = async (value) => {
    if (value) await deleteUser(selectedIdToDelete);

    setIsOpenDialog(false);
  };

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  //
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [Users, setUsers] = useState([]);

  // handle-errors-responses
  async function handleResponseErrorsForGetUsers(response) {
    if (response.status) {
      if (response.code === 200) {
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      }
    }
    return false;
  }
  async function handleResponseErrorsForDeleteUser(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("تم حذف المستخدم بنجاح !");
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      } else if (response.code === 404) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("ليس هناك اي مستخدم للحذف !");
      }
    }
    return false;
  }

  // delete-user
  const deleteUser = async (id) => {
    setIsSubmitting(true);

    try {
      const response = await User.delete(id);
      await sleep(1000);

      if (await handleResponseErrorsForDeleteUser(response)) {
        await getUsers();
      }
    } catch (error) {
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("خطا..حاول مرة اخرى !");
    }
  };

  // get-user
  const getUsers = async () => {
    setIsSubmitting(true);

    try {
      const form = {
        itemPerPage: itemPerPage,
        currentPage: currentPage,
      };
      const response = await User.list(form);
      await sleep(1000);

      if (await handleResponseErrorsForGetUsers(response)) {
        setUsers(Object.values(response.data.data));
        setCounterPages(response.data.last_page);
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("! حدث خطا..حاول مرة اخرى");
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, itemPerPage]);

  useEffect(() => {
    document.title = "مختفون | قائمة المستخدمين";
  });

  return (
    <>
      <Pagination
        counterPages={counterPages}
        itemPerPage={handleItemPerPageChange}
        currentPage={handleCurrentPageChange}
      />

      <PaperUsers
        isSubmitting={isSubmitting}
        data={Users}
        deleteId={handleOpenDialogChange}
      />

      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
      <AlertDialog
        confirmation={handleDeleteConfirmationChange}
        isOpen={isOpenDialog}
      />
    </>
  );
}

export default ListingUsers;
