//@Mui
import Grid from "@mui/material/Grid";
//@Fields
import FormField from "../form-field";

function resetPasswordForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  let { email } = formField;
  let { email: emailValue } = values;

  return (
    <Grid container spacing={3} sx={{ paddingBottom: 7 }}>
      <Grid item xs={12} sm={12}>
        <FormField
          type={email.type}
          label={email.label}
          name={email.name}
          value={emailValue}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={(emailValue.length > 0 && !errors.email).toString()}
        />
      </Grid>
    </Grid>
  );
}

export default resetPasswordForm;
