/* eslint-disable no-console */
/* eslint-disable no-useless-concat */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-console
// eslint-disable-next-line no-param-reassign

// @axios
import axios from "axios";
// @js-Cookie
import Cookies from "js-cookie";
// @crypto-js
import CryptoJS from "crypto-js";

// decrypt-token
function decryptToken(token) {
  // random encryption key
  const key = "8yNTw%12tLxG$!D7";

  // II-decrypte token
  const bytes = CryptoJS.AES.decrypt(token, key);
  const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);

  return decryptedToken;
}

export const urlToAPi = "https://www.moukhtafoun.online/back-end/public/api/v1";
export const pathToImg = "https://www.moukhtafoun.online/back-end/storage/app/";
// export const urlToAPi =
//   "http://localhost/My-Project/FindThem/back-end/public/api/v1";
// export const pathToImg =
//   "http://localhost/My-Project/FindThem/back-end/storage/app/";

export const key = "8yNTw%12tLxG$!D7";

export default axios.create({
  baseURL: urlToAPi,
});

axios.defaults.baseURL = urlToAPi;

axios.interceptors.request.use((config) => {
  const cookieToken = Cookies.get("token");

  let token = null;

  if (cookieToken !== undefined) {
    token = decryptToken(cookieToken);
  }

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const responseBody = (response) => response.data;

const headers = {
  headers: {
    Accept: "application/json, text/plain, image/*, application/pdf, */*",
    "Content-Type": "application/json",
  },
};

const isUndefined = (val) => {
  return val === undefined ? "" : val;
};

const requests = {
  get: (url) => axios.get(url).then(responseBody),
  post: (url, body) => axios.post(url, body, headers).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  del: (url, body) => axios.delete(url, body, headers).then(responseBody),
  patch: (url, body) => axios.patch(url, body, headers).then(responseBody),
};

export const Auth = {
  register: (form) => requests.post("/auth/register", form),
  login: (form) => requests.post("/auth/login", form),
  logout: () => requests.post("/auth/logout"),
  me: () => requests.post("/auth/me"),
  resetPassword: (form) => requests.post("/auth/resetPassword", form),
};

export const Disappeared = {
  list: (form) =>
    requests.get(
      form.isFiltration
        ? `/disappeared?first_name=${isUndefined(
            form.first_name
          )}&last_name=${isUndefined(
            form.last_name
          )}&type_disappeared=${isUndefined(
            form.type_disappeared
          )}&gender=${isUndefined(form.gender)}&direction=${isUndefined(
            form.direction
          )}&province=${isUndefined(form.province)}&city=${isUndefined(
            form.city
          )}&date_lost=${isUndefined(form.date_lost)}&is_found=${isUndefined(
            form.is_found
          )}&itemPerPage=${form.itemPerPage}&currentPage=${form.currentPage}`
        : `/disappeared?itemPerPage=${form.itemPerPage}&currentPage=${form.currentPage}`
    ),
  details: (id) => requests.get(`/disappeared/${id}`),
  create: (form) => requests.post("/disappeared", form),
  edit: (form, id) => requests.put(`/disappeared/${id}`, form),
  delete: (id) => requests.del(`/disappeared/${id}`),
  comments: (id, form) =>
    requests.get(
      form === undefined
        ? `/disappeared/${id}/comments`
        : `/disappeared/${id}/comments?itemPerPage=${form.itemPerPage}&currentPage=${form.currentPage}`
    ),
};

export const Comment = {
  details: (id) => requests.get(`/comment/${id}`),
  create: (form) => requests.post("/comment", form),
  edit: (form, id) => requests.put(`/comment/${id}`, form),
  delete: (id) => requests.del(`/comment/${id}`),
};

export const User = {
  list: (form) =>
    requests.get(
      `/user?itemPerPage=${form.itemPerPage}&currentPage=${form.currentPage}`
    ),
  comments: (query) =>
    requests.get(
      `/user/relation/comments?itemPerPage=${query.itemPerPage}&currentPage=${query.currentPage}`
    ),
  disappeareds: (form) =>
    requests.get(
      form === undefined
        ? "/user/relation/disappeareds"
        : `/user/relation/disappeareds?itemPerPage=${form.itemPerPage}&currentPage=${form.currentPage}`
    ),
  delete: (id) => requests.del(`/user/${id}`),
  generationResetPassword: (form) =>
    requests.put("/user/generate/linkReset", form),
};

export const File = {
  upload: (file) => requests.post("/file/upload", file),
  destroy: (path) => requests.post("/file/destroy", path),
};

export const Email = {
  resetPasswordLink: (form) => requests.post("/send-email", form),
};
