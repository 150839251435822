/* eslint-disable jsx-a11y/anchor-is-valid */

// @css-file
import "./index.css";
// @mui-components
import { Button } from "@mui/material";
// @rect
import { useEffect } from "react";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @mui-icons
import HomeIcon from "@mui/icons-material/Home";

function Error() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Error | Moukhtafoun";
  });

  return (
    <a
      href="#"
      onClick={(e) => [e.preventDefault(), navigate("/")]}
      className="back_all"
    >
      <header className="top-header"></header>

      <div>
        <div className="starsec"></div>
        <div className="starthird"></div>
        <div className="starfourth"></div>
        <div className="starfifth"></div>
      </div>

      <div className="lamp__wrap">
        <div className="lamp">
          <div className="cable"></div>
          <div className="coverrr"></div>
          <div className="in-cover">
            <div className="bulb"></div>
          </div>
          <div className="light"></div>
        </div>
      </div>
      <section className="error">
        <div className="error__content">
          <div className="error__message message">
            <h1 className="message__title">الصفحة غير موجودة</h1>
            <p className="message__text">
              نحن آسفون ، الصفحة التي تبحث عنها لا يمكن العثور عليها. هنا. قد
              يكون الارتباط الذي اتبعته معطلاً أو لم يعد موجودًا. يرجى المحاولة
              مرة أخرى أو انظر لدينا.
            </p>
          </div>
          <Button
            onClick={() => navigate("/")}
            size="small"
            startIcon={<HomeIcon fontSize="20px" className="header_btn_icon" />}
            sx={{
              mt: 5,
              border: "2px solid #1CA4C7",
              background: "#1CA4C7",
              color: "#BEF2FF",
              ":hover": {
                color: "#1CA4C7",
              },
            }}
          >
            <span className="header_btn_txtPrincip">العودة الى الرئيسية </span>
          </Button>
        </div>
      </section>
    </a>
  );
}

export default Error;
