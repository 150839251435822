const form = {
  formId: "edit-disappeared",
  formField: {
    firstname: {
      name: "firstname",
      label: "الاسم الشخصي للمختفي(ة)",
      type: "text",
      errorMsg: ".الاسم الشخصي للمختفي(ة) مطلوب ",
      minValue: ".أربعة أحرف على الأقل",
      invalidMsg: ".الاسم الشخصي للمختفي(ة) غير صالح",
      placeholder: "الرجاء إدخال الاسم الشخصي للمختفي(ة)",
    },
    lastname: {
      name: "lastname",
      label: "الاسم العائلي للمختفي(ة)",
      type: "text",
      errorMsg: ".الاسم العائلي للمختفي(ة) مطلوب ",
      minValue: ".أربعة أحرف على الأقل",
      invalidMsg: ".الاسم العائلي للمختفي(ة) غير صالح",
      placeholder: "الرجاء إدخال الاسم العائلي للمختفي(ة)",
    },
    age: {
      name: "age",
      label: "عمر المختفي(ة)",
      type: "number",
      errorMsg: ".عمر المختفي(ة) مطلوب",
      minValue: ".رقيمن على الأقل",
      invalidMsg: ".عمر المختفي(ة) غير صالح",
      placeholder: ".الرجاء إدخال عمر المختفي(ة)",
    },
    datedisappeared: {
      name: "datedisappeared",
      label: "تاريخ الإختفاء",
      type: "date",
      errorMsg: ".تاريخ الإختفاء مطلوب ",
      minValue: ".ستة أرقام على الأقل",
      invalidMsg: ".تاريخ الإختفاء غير صالح",
      placeholder: "الرجاء إدخال تاريخ الإختفاء",
    },
    gender: {
      name: "gender",
      label: "جنس المختفي(ة)",
      errorMsg: ".جنس المختفي(ة) مطلوب",
      minValue: "",
      invalidMsg: ".جنس المختفي(ة) غير صالح",
      placeholder: "الرجاء إدخال جنس المختفي(ة)",
    },
    direction: {
      name: "direction",
      label: "جهة الإختفاء",
      errorMsg: ".جهة الإختفاء مطلوبة",
      minValue: "",
      invalidMsg: ".جهة الإختفاء غير صالحة",
      placeholder: " الرجاء إدخال جهة الإختفاء",
    },
    province: {
      name: "province",
      label: "عمالة او اقليم الإختفاء",
      errorMsg: ".عمالة او اقليم الإختفاء مطلوب ",
      minValue: "",
      invalidMsg: ".عمالة او اقليم الإختفاء غير صالح",
      placeholder: "الرجاء إدخال عمالة او اقليم الإختفاء",
    },
    otherProvince: {
      name: "otherProvince",
      label: "عمالة او اقليم الإختفاء",
      type: "text",
      errorMsg: ".عمالة او اقليم الإختفاء مطلوب ",
      minValue: "",
      invalidMsg: ".عمالة او اقليم الإختفاء غير صالح",
      placeholder: "الرجاء إدخال عمالة او اقليم الإختفاء",
    },
    city: {
      name: "city",
      label: "مدينة او قرية الإختفاء",
      errorMsg: ".مدينة او قرية الإختفاء مطلوبة",
      minValue: "",
      invalidMsg: ".مدينة او قرية الإختفاء غير صالحة",
      placeholder: "الرجاء إدخال مدينة او قرية الإختفاء",
    },
    otherCity: {
      name: "otherCity",
      label: "مدينة او قرية الإختفاء",
      type: "text",
      errorMsg: ".مدينة او قرية الإختفاء مطلوبة",
      minValue: "",
      invalidMsg: ".مدينة او قرية الإختفاء غير صالحة",
      placeholder: "الرجاء إدخال مدينة او قرية الإختفاء",
    },
    typedisappeared: {
      name: "typedisappeared",
      label: "صنف المختفي",
      errorMsg: ".صنف المختفي مطلوب ",
      minValue: "",
      invalidMsg: ".صنف المختفي غير صالح",
      placeholder: "الرجاء إدخال صنف المختفي",
    },
    phonenumber: {
      name: "phonenumber",
      label: "رقم الهاتف لأسرة المختفي(ة)",
      type: "tel",
      errorMsg: "رقم الهاتف لأسرة المختفي(ة) مطلوب ",
      minValue: ".عشرة أرقام على الأقل",
      invalidMsg: "رقم الهاتف لأسرة المختفي(ة) غير صالح",
      placeholder: "الرجاء إدخال رقم الهاتف لأسرة المختفي(ة)",
    },
    is_found: {
      name: "is_found",
      label: "هل وجد(ت) المختفي(ة) ؟",
      errorMsg: ".صنف المختفي مطلوب ",
      minValue: "",
      invalidMsg: "",
      placeholder: "",
    },
    imagedisappeared: {
      name: "imagedisappeared",
      type: "file",
      label: "صورة المختفي(ة)",
      errorMsg: ".صورة المختفي(ة) مطلوبة ",
      maxSize: ".الحجم الأقصى للصورة هو : 20 ميغا",
      fileTypes: ".نوع الصورة  الصالحة :  پنج، جپج ",
      invalidMsg: ".صورة المختفي(ة) غير صالحة",
      placeholder: "الرجاء إدخال صورة المختفي(ة)",
    },
  },
};
export default form;
