const form = {
  formId: "login",
  formField: {
    email: {
      name: "email",
      label: "البريد الالكتروني",
      type: "email",
      errorMsg: ".البريد الالكتروني مطلوب ",
      minValue: "",
      invalidMsg: ".البريد الالكتروني غير صالح",
      placeholder: "الرجاء إدخال البريد الالكتروني",
    },
    password: {
      name: "password",
      label: "كلمة السر",
      type: "password",
      errorMsg: ".كلمة السر مطلوب ",
      minValue: ".ثمانية أحرف على الأقل",
      invalidMsg:
        "كلمة السر يجب ان تحتوي على : <br /> -رقم واحد على الأقل 1 ،2 ،3...<br /> -حرف صغير واحد على الأقل c ،b ،a...<br /> -حرف كبير واحد على الأقل C ،B ،A... <br /> -يتراوح بين 8 و50 حرفًا",
      placeholder: "الرجاء إدخال كلمة السر",
    },
  },
};

export default form;
