/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// @mui-icons
import TimerOffIcon from "@mui/icons-material/TimerOff";
// @mui
import { Card, Box, Typography } from "@mui/material";
// @react-router-dom
import { useNavigate } from "react-router-dom";

function ExpiredLinkMsg({ expiredTime }) {
  // use-@react-router-dom
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: "fit-content",
        width: "300px",
        background: "#fff",
      }}
    >
      <Box
        color="#000"
        background="info"
        variant="gradient"
        borderRadius="lg"
        shadow="lg"
        opacity={1}
        sx={{
          textTransform: "uppercase",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TimerOffIcon sx={{ fontSize: "80px", mt: 2, mb: 2 }} color="error" />
        <Typography sx={{ textAlign: "center" }} variant="body" component="h4">
          انتهت صلاحية رابط إعادة تعيين كلمة المرور {expiredTime}
        </Typography>

        <div className="register">
          <p>
            قم بانشاء{" "}
            <a
              onClick={(e) => [
                e.preventDefault(),
                navigate("/auth/reset-password"),
              ]}
              href="#"
              style={{ color: "#D32F2F" }}
            >
              رابط جديد
            </a>
          </p>
        </div>
      </Box>
    </Card>
  );
}

export default ExpiredLinkMsg;
