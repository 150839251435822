//@img
import l3iba from "../img/l3iba.png";
import { Tooltip } from "@mui/material";

function SectionTitle({ title, subTitle }) {
  return (
    <div className="fidthem_title">
      <Tooltip title="Soon, Will be free..." placement="top">
        <img
          src={l3iba}
          alt="fingthem_icon_behind"
          className="fingthem_icon_behind"
        />
      </Tooltip>
      <div className="fidthem_title_line_content">
        <span className="fingthem_txt">{title}</span>
        <span className="findthem_title_line"></span>
        <span className="fingthem_txt_underline">{subTitle}</span>
      </div>
      <Tooltip title="...قريبا, ستتحرر" placement="top">
        <img
          style={{ transform: "rotateY(180deg)" }}
          src={l3iba}
          alt="fingthem_icon_behind"
          className="fingthem_icon_behind"
        />
      </Tooltip>
    </div>
  );
}
export default SectionTitle;
