import React from "react";

// @Img
import logo from "../img/logo.png";
function Loading() {
  return (
    <div className="over_loading">
      <img src={logo} alt="logo_loading" className="over_loading_img" />
      <p className="over_loading_txt"></p>
    </div>
  );
}

export default Loading;
