// @crypto-js
import CryptoJS from "crypto-js";
// random encryption key
import { key } from "../utils/fetcher-api";

export default async function decryptData(data) {
  try {
    // II-decrypte token
    const bytes = CryptoJS.AES.decrypt(data, key);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData;
  } catch (error) {
    return "error";
  }
}
