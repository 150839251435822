/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

// @react
import { useEffect, useState } from "react";
// @Cookies
import Cookies from "js-cookie";
// @API
import { Auth } from "../utils/fetcher-api";
// @react-router-dom
import { useNavigate } from "react-router-dom";
function userStatus(redirect) {
  // use-@react-router-dom
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(Cookies.get("token"));

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const getAuthUser = async () => {
    try {
      const response = await Auth.me();

      sleep(1000);

      if (response.status && response.code === 200) {
        setUserData(response.data);
        setIsAuthenticated(true);
      }
      if (!response.status && redirect) {
        setIsAuthenticated(false);
        navigate("/auth/sign-in");
      }
    } catch (error) {
      setIsAuthenticated(false);
      navigate("/auth/sign-in");
    }
  };

  useEffect(() => {
    getAuthUser();
  }, [token]);

  return { isAuthenticated, userData };
}

export default userStatus;
