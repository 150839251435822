/* eslint-disable no-undef */

// Joy-Components
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
// Mui-Components
import { Box, IconButton, Tooltip } from "@mui/material";
// Mui-Icons
import RoomIcon from "@mui/icons-material/Room";
import ChatIcon from "@mui/icons-material/Chat";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import ShareIcon from "@mui/icons-material/Share";
// @Components
import Chips from "./chips";
import { pathToImg } from "../utils/fetcher-api";
// react-share
import { FacebookShareButton } from "react-share";

export default function CardDisappeared(props) {
  const { data, inHome } = props;

  const handleStatusComment = (id) => {
    const commentStatus = {
      disappearedId: id,
      status: true,
    };
    props.commentStatus(commentStatus);
  };
  return (
    <Card
      key={data.id}
      variant="outlined"
      sx={{
        borderColor: "#1CA4C7",
        background: "#BEF2FF",
        width: { xs: 250, sm: 280 },
        mt: 2,
        mb: 2,
        height: "fit-content",
      }}
    >
      <CardOverflow
        sx={{
          position: "relative",
        }}
      >
        <AspectRatio ratio="1.3">
          <img
            style={{ objectFit: "fill" }}
            src={`${pathToImg}${data.picture}`}
            srcSet={`${pathToImg}${data.picture}`}
            alt={`img-${data.id}`}
          />
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              bottom: 1,
              right: 0,
              display: inHome ? "none" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#00000094",
            }}
          >
            <Box>
              <Tooltip title="تعليقات عن المختفي" placement="top">
                <IconButton onClick={() => handleStatusComment(data.id)}>
                  <ChatIcon
                    sx={{
                      background: "#BEF2FF",
                      color: "#1CA4C7",
                      border: "2px solid #1CA4C7",
                      borderRadius: "50%",
                      padding: 0.5,
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="شارك على فيسبوك" placement="top">
                <IconButton>
                  <FacebookShareButton
                    className="share-button"
                    url={data?.fb_post_link}
                  >
                    <ShareIcon
                      sx={{
                        background: "#BEF2FF",
                        color: "#1CA4C7",
                        border: "2px solid #1CA4C7",
                        borderRadius: "50%",
                        padding: 0.5,
                      }}
                    />
                  </FacebookShareButton>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography
              level="body3"
              sx={{ fontWeight: "md", mr: 1, color: "#fff" }}
            >
              {data.created_at?.substring(0, 10)} نشر في
            </Typography>
          </Box>
        </AspectRatio>
      </CardOverflow>
      <Typography
        level="h2"
        sx={{
          textAlign: "right",
          fontWeight: "md",
          fontSize: "md",
          mt: 2,
          color: "#1CA4C7",
        }}
      >
        {data.first_name} {data.last_name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          level="body2"
          sx={{ textAlign: "right", mb: 2, mt: 1.2, color: "#1CA4C7" }}
        >
          سنة
        </Typography>
        <Typography
          level="body2"
          sx={{ textAlign: "right", mb: 2, mt: 1.2, ml: 0.5, color: "#1CA4C7" }}
        >
          {data.age}
        </Typography>
        {data.gender === "1" ? (
          <ManIcon sx={{ color: "#1CA4C7", mr: 1 }} />
        ) : (
          <WomanIcon sx={{ color: "#1CA4C7", mr: 1 }} />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          level="body2"
          sx={{ textAlign: "right", mt: 1, mb: 2, color: "#1CA4C7" }}
        >
          {data.direction}، {data.province}، {data.city}
        </Typography>
        <RoomIcon sx={{ color: "#1CA4C7" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          level="body2"
          sx={{ textAlign: "right", mt: 1, mb: 2, color: "#1CA4C7" }}
        >
          {data.phone}
        </Typography>
        <PhoneEnabledIcon sx={{ color: "#1CA4C7" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          level="body2"
          sx={{ textAlign: "right", mb: 2, color: "#1CA4C7" }}
        >
          {data.date_losted} اختفى(ت) في
        </Typography>
        <CalendarMonthIcon sx={{ color: "#1CA4C7" }} />
      </Box>
      <Divider />
      <CardOverflow
        variant="soft"
        sx={{
          display: "flex",
          gap: 1.5,
          py: 1.5,
          px: "var(--Card-padding)",
          bgcolor: "background.level1",
          justifyContent: "center",
        }}
      >
        {data.is_found === "not" ? (
          <Chips type="default" label="مختفي(ة)" />
        ) : (
          <Chips type="found" label="وجد(ت)" />
        )}
        <Divider orientation="vertical" />
        <Chips type="default" label="متغيب(ة)" />
      </CardOverflow>
    </Card>
  );
}
