import checkout from "./form";

const {
  formField: {
    first_name,
    last_name,
    age,
    gender,
    direction,
    province,
    city,
    date_disappeared,
    type_disappeared,
    is_found,
  },
} = checkout;

const initialValues = {
  [first_name.name]: "",
  [last_name.name]: "",
  [age.name]: "",
  [gender.name]: "",
  [direction.name]: "",
  [province.name]: "",
  [city.name]: "",
  [date_disappeared.name]: "",
  [type_disappeared.name]: "",
  [is_found.name]: "",
};
export default initialValues;
