// Regular Exepssion
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const FloatRegExp = /^\d\d(\.\d\d)?$/;
// File Size
export const FileSize_IMG = 8000000; //=8Mo
export const FileSize_PDF = 12000000; //=12Mo
// Format Accepted for Files
export const SUPPORTED_FORMATS_IMG = ["image/jpeg", "image/jpg", "image/png"];
export const SUPPORTED_FORMATS_PDF = ["image/jpeg", "image/jpg", "image/png"];
// password
export const PasswordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;
