/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */

// @react
import { useEffect, useState } from "react";

// @css
import "./index.css";
// @Formik
import { Form, Formik } from "formik";
// @Formik-Components
import NewPasswordForm from "./components/new-password-form";
import form from "./components/schemas/form";
import validations from "./components/schemas/validations";
import initialValues from "./components/schemas/initiale-values";
// @mui
import { Card, Box, Button } from "@mui/material";
// @snackbar
import Snackbar from "../../../components/Snackbar";
// @api
import { Auth } from "../../../utils/fetcher-api";
// @react-router-dom
import { useNavigate, useParams } from "react-router-dom";
// @components
import { getCurrentDateTime } from "../../../components/get-current-date-time";
import decryptData from "../../../components/decrypt-data";
import TimePassed from "../../../components/time-passed";
import ExpiredLinkMsg from "../../../components/expired-link-msg";
import DecryteLinkErrorMsg from "../../../components/decryte-link-error-msg";

function newPassword() {
  // use-@react-router-dom
  const navigate = useNavigate();
  const { generateLink } = useParams();

  // use @Formik For New Password
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [IsSubmitting, setIsSubmitting] = useState(false);
  //
  const [email, setEmail] = useState("");
  const [errorDecrypting, setErrorDecrypting] = useState(false);
  const [isGenerateLinkExpired, setIsGenerateLinkExpired] = useState(false);
  const [expiredTime, setExpiredTime] = useState("منذ 4 ساعات");

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // handle-response-&-errors
  async function handleResponseErrors(response) {
    if (response.status) {
      if (response.code === 200) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("! تم استرجاع كلمة السر بنجاح");

        await sleep(1000);
        navigate("/auth/sign-in");
      }
    } else {
      if (response.code === 404) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! البريد الإلكتروني غير صحيح");
      } else if (response.code === 422) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! المعطيات غير صالحة");
      }
    }
    setIsSubmitting(false);
  }

  // submit-ResetPassword
  const submitResetPassword = async (values, actions) => {
    actions.setSubmitting(true);
    setIsSubmitting(true);
    await sleep(1000);

    const { password, confirmationPassword } = values;
    const currentDateTime = await getCurrentDateTime();

    const resetPassword = {
      email: email,
      password: password,
      password_confirmation: confirmationPassword,
      updated_at: currentDateTime,
    };

    try {
      const response = await Auth.resetPassword(resetPassword);

      // handle-response-&-errors
      handleResponseErrors(response);

      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage(".خطأ، حاول مرة أخرى");
    }
  };

  const handleSubmit = (values, actions) => {
    setIsSubmitting(true);
    if (!isGenerateLinkExpired) submitResetPassword(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
    setIsSubmitting(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const isLinkExpired = (dateTime) => {
    const TP = TimePassed(dateTime);
    console.log(TP, dateTime);
    setExpiredTime(TP);
    const isExp = ![
      "دقيقة",
      "دقائق",
      "دقيقتين",
      "ساعة",
      "ساعتين",
      "ساعات",
      "ثوان",
      "ثانية",
    ].some((str) => TP.includes(str));

    return isExp;
  };

  const subStringLink = async (link) => {
    const decryptLink = await decryptData(link);

    await sleep(1000);
    if (decryptLink === "error" || decryptLink === "") {
      setErrorDecrypting(true);
    } else {
      setErrorDecrypting(false);
      const subLink = decryptLink?.split("*");
      setEmail(subLink[0]);

      setIsGenerateLinkExpired(isLinkExpired(subLink[1]));
    }
  };

  useEffect(() => {
    subStringLink(generateLink.replace(/--/g, "/"));
  }, [generateLink]);

  useEffect(() => {
    document.title = "مختفون | إنشاء كلمة سر جديدة";
  });
  return (
    <>
      <Box className="box">
        {isGenerateLinkExpired ? (
          <ExpiredLinkMsg expiredTime={expiredTime} />
        ) : errorDecrypting ? (
          <DecryteLinkErrorMsg />
        ) : (
          <section className="section">
            <h1>إنشاء كلمة سر جديدة</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                resetForm,
              }) => (
                <Form
                  dir="rtl"
                  id={formId}
                  autoComplete="off"
                  style={{
                    pointerEvents: IsSubmitting ? "none" : "painted",
                    opacity: IsSubmitting ? 0.4 : 1,
                  }}
                >
                  <Card
                    sx={{
                      height: "100%",
                      background: "transparent",
                    }}
                  >
                    <Box
                      color="white"
                      background="info"
                      variant="gradient"
                      borderRadius="lg"
                      shadow="lg"
                      opacity={1}
                      sx={{ textTransform: "uppercase" }}
                    >
                      <Box p={2}>
                        <Box sx={{ pt: 3 }}>
                          <NewPasswordForm
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                              handleChange,
                              handleBlur,
                            }}
                          />
                        </Box>

                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Button
                            className="button"
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="info"
                          >
                            {IsSubmitting ? "..." : "إنشاء كلمة السر"}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Form>
              )}
            </Formik>

            <div className="register">
              <p>
                ليس لديك حساب{" "}
                <a
                  onClick={(e) => [
                    e.preventDefault(),
                    navigate("/auth/sign-up"),
                  ]}
                  href="#"
                >
                  تسجل الان
                </a>
              </p>
            </div>
          </section>
        )}
      </Box>
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
    </>
  );
}

export default newPassword;
