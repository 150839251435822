// @MuiComponents
import Grid from "@mui/material/Grid";

// @Field
import FormField from "../FormField";

function FiltrationForm({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    Genders,
    directions,
    provinces,
    cities,
    typesDisappeared,
    isFoundOptions,
  } = formData;

  const {
    first_name,
    last_name,
    age,
    date_disappeared,
    gender,
    direction,
    province,
    city,
    type_disappeared,
    is_found,
  } = formField;

  let {
    first_name: first_nameValue,
    last_name: last_nameValue,
    age: ageValue,
    date_disappeared: date_disappearedValue,
    gender: genderValue,
    direction: directionValue,
    province: provinceValue,
    city: cityValue,
    type_disappeared: type_disappearedValue,
    is_found: is_foundValue,
  } = values;

  return (
    <Grid container spacing={3} sx={{ padding: 3 }}>
      <Grid item xs={12} sm={12}>
        <FormField
          type={first_name.type}
          label={first_name.label}
          name={first_name.name}
          value={first_nameValue}
          placeholder={first_name.placeholder}
          error={errors.first_name && touched.first_name}
          success={(
            first_nameValue.length > 0 && !errors.first_name
          ).toString()}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          type={last_name.type}
          label={last_name.label}
          name={last_name.name}
          value={last_nameValue}
          placeholder={last_name.placeholder}
          error={errors.last_name && touched.last_name}
          success={(last_nameValue.length > 0 && !errors.last_name).toString()}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          type={age.type}
          label={age.label}
          name={age.name}
          value={ageValue}
          placeholder={age.placeholder}
          error={errors.age && touched.age}
          success={(ageValue.length > 0 && !errors.age).toString()}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          type={date_disappeared.type}
          label={date_disappeared.label}
          name={date_disappeared.name}
          value={date_disappearedValue}
          placeholder={date_disappeared.placeholder}
          error={errors.date_disappeared && touched.date_disappeared}
          success={(
            date_disappearedValue.length > 0 && !errors.date_disappeared
          ).toString()}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          selectOptions={Genders}
          isGroupRadio
          type={gender.type}
          label={gender.label}
          name={gender.name}
          value={genderValue}
          error={errors.gender && touched.gender}
          success={(genderValue.length > 0 && !errors.gender).toString()}
          placeholder={gender.placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          selectOptions={directions}
          isAutoComplete
          type={direction.type}
          label={direction.label}
          name={direction.name}
          value={directionValue}
          error={errors.direction && touched.direction}
          success={(directionValue?.length > 0 && !errors.direction).toString()}
          placeholder={direction.placeholder}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          selectOptions={provinces.filter(
            (p) => p.direction === directionValue
          )}
          isAutoComplete
          type={province.type}
          label={province.label}
          name={province.name}
          value={provinceValue}
          error={errors.province && touched.province}
          success={(provinceValue?.length > 0 && !errors.province).toString()}
          placeholder={province.placeholder}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          selectOptions={cities.filter((c) => c.province === provinceValue)}
          isAutoComplete
          type={city.type}
          label={city.label}
          name={city.name}
          value={cityValue}
          error={errors.city && touched.city}
          success={(cityValue?.length > 0 && !errors.city).toString()}
          placeholder={city.placeholder}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          selectOptions={typesDisappeared}
          isGroupRadio
          type={type_disappeared.type}
          label={type_disappeared.label}
          name={type_disappeared.name}
          value={type_disappearedValue}
          error={errors.type_disappeared && touched.type_disappeared}
          success={(
            type_disappearedValue.length > 0 && !errors.type_disappeared
          ).toString()}
          placeholder={type_disappeared.placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          selectOptions={isFoundOptions}
          isGroupRadio
          type={is_found.type}
          label={is_found.label}
          name={is_found.name}
          value={is_foundValue}
          error={errors.is_found && touched.is_found}
          success={(is_foundValue.length > 0 && !errors.is_found).toString()}
          placeholder={is_found.placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
    </Grid>
  );
}

export default FiltrationForm;
