// @form
import checkout from "./form";

const {
  formField: { password, confirmationPassword },
} = checkout;

const initialValues = {
  [password.name]: "",
  [confirmationPassword.name]: "",
};

export default initialValues;
