/* eslint-disable react-hooks/exhaustive-deps */

// @react
import { useState, useEffect } from "react";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @components
import Collapse from "../../components/collapse";
import Snackbar from "../../components/Snackbar";
import userStatus from "../../components/userStatus";
import AlertDialog from "../../components/alert-dialog";
// @mui-components
import { Box } from "@mui/material";
// @API
import { Comment, Disappeared, User } from "../../utils/fetcher-api";

function ListingDisappearedWithComments(props) {
  // use-@react-router-dom
  const navigate = useNavigate();
  const [itemPerPage, setItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [disappearedComments, setDisappearedComments] = useState([]);
  const [disappeareds, setDisappeareds] = useState([]);
  const [counter, setCounter] = useState(null);
  const [isSubmittingCom, setIsSubmittingCom] = useState(true);
  const [isSubmittingDisap, setIsSubmittingDisap] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState("");

  // alert-dialog
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState(null);

  // user-status
  const { userData } = userStatus(true);

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };
  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleCurrentPageChange = (value) => {
    setCurrentPage(value);
  };

  const handleItemPerPageChange = (value) => {
    setItemPerPage(value);
  };

  const handleClickedIdChange = (value) => {
    setSelectedId(value);
  };

  const handleOpenDialogChange = (value, type) => {
    setTypeToDelete(type);
    setSelectedIdToDelete(value);
    setIsOpenDialog(true);
  };

  const handleDeleteConfirmationChange = async (value) => {
    if (value && typeToDelete === "disappeared") {
      await deleteDisappeared(selectedIdToDelete);
    } else if (value && typeToDelete === "comment") {
      await deleteComment(selectedIdToDelete);
    }
    setIsOpenDialog(false);
  };

  // handle-response-&-errors
  async function handleResponseErrorsForGetDispCom(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsSubmittingCom(false);
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 404) {
        setDisappearedComments([]);
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("ليس للمختفي اي تعليق حاليا !");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      }
    }
    setIsSubmittingCom(false);
    return false;
  }
  async function handleResponseErrorsForGetUserDisp(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsSubmittingCom(false);
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 404) {
        setDisappeareds([]);
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("لم تقم باضافي اي مختفي حاليا !");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      }
    }
    setIsSubmittingCom(false);
    return false;
  }
  async function handleResponseErrorsForDeleteDisap(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("تم حذف المختفي بنجاح !");

        setIsSubmittingDisap(false);
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      } else if (response.code === 404) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("ليس هناك اي مختفي للحذف !");
      }
    }
    setIsSubmittingDisap(false);
    return false;
  }
  async function handleResponseErrorsForDeleteCom(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("تم حذف التعليق بنجاح !");
        setIsSubmittingCom(true);
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      } else if (response.code === 404) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("ليس هناك اي تعليق للحذف !");
      }
    }
    setIsSubmittingCom(true);
    return false;
  }
  // get-comments-of-disappeared-ID
  const getDisappearedComments = async () => {
    setIsSubmittingCom(true);
    try {
      const response = await Disappeared.comments(selectedId);
      await sleep(1000);
      if (await handleResponseErrorsForGetDispCom(response)) {
        setDisappearedComments(Object.values(response.data.data));
      }
    } catch (error) {
      setIsSubmittingCom(false);
    }
  };

  useEffect(() => {
    if (selectedId !== null) {
      getDisappearedComments();
    } else {
      setDisappearedComments([]);
    }
  }, [selectedId]);

  // get-disappeared-created-by-auth-user
  const getUserDisappeareds = async () => {
    setIsSubmittingDisap(true);

    try {
      const form = {
        itemPerPage: itemPerPage,
        currentPage: currentPage,
        isFiltration: false,
      };
      const response = await (userData?.user_type === "admin"
        ? Disappeared.list(form)
        : User.disappeareds(form));
      await sleep(1000);
      if (await handleResponseErrorsForGetUserDisp(response)) {
        setCounter(response?.data?.last_page);
        setDisappeareds(Object.values(response.data.data));
      }

      setIsSubmittingDisap(false);
    } catch (error) {
      setIsSubmittingDisap(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("! 22حدث خطا..حاول مرة اخرى");
    }
  };

  useEffect(() => {
    getUserDisappeareds();
  }, [itemPerPage, currentPage]);

  // delete-disappeared
  const deleteDisappeared = async (value) => {
    setIsSubmittingDisap(true);

    try {
      const response = await Disappeared.delete(value);
      await sleep(1000);

      if (await handleResponseErrorsForDeleteDisap(response))
        await getUserDisappeareds();
    } catch (error) {
      setIsSubmittingDisap(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("خطا..حاول مرة اخرى !");
    }
  };

  // edit-disappeared
  const handleEditDisappeared = (value) => {
    props.editId(value);
  };

  // edit-comment
  const handleEditComment = (value) => {
    props.editCommentId(value);
  };

  // delete-comment
  const deleteComment = async (value) => {
    setIsSubmittingDisap(true);

    try {
      const response = await Comment.delete(value);
      await sleep(1000);

      if (await handleResponseErrorsForDeleteCom(response)) {
        // await getUserDisappeareds();
        await getDisappearedComments();
        setIsSubmittingDisap(false);
      }
    } catch (error) {
      setIsSubmittingDisap(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("خطا..حاول مرة اخرى !");
    }
  };

  useEffect(() => {
    document.title = "مختفون | قائمة المختفين مع التعليقات";
  });

  return (
    <>
      <Box dir="rtl">
        <Collapse
          itemPerPage={handleItemPerPageChange}
          currentPage={handleCurrentPageChange}
          counterPages={counter === null ? 1 : counter}
          //disappearedID
          deleteId={(value) => handleOpenDialogChange(value, "disappeared")}
          editId={handleEditDisappeared}
          clickedId={handleClickedIdChange}
          //commentID
          deleteCommentId={(value) => handleOpenDialogChange(value, "comment")}
          editCommentId={handleEditComment}
          //data
          comments={disappearedComments}
          disappeareds={disappeareds}
          selectedId={selectedId}
          isSubmittingCom={isSubmittingCom}
          isSubmittingDisap={isSubmittingDisap}
        />
      </Box>
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
      <AlertDialog
        confirmation={handleDeleteConfirmationChange}
        isOpen={isOpenDialog}
      />
    </>
  );
}

export default ListingDisappearedWithComments;
