// @Mui
import { Skeleton, Box } from "@mui/material";

function ItemsSkeleton() {
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <Box className="cards" sx={{ alignItems: "center", overflow: "hidden" }}>
      {arr.map((item) => {
        return (
          <Box
            sx={{
              pt: 2,
              width: { xs: 230, sm: 250 },
            }}
            key={item}
          >
            <Skeleton variant="rectangular" height={118} />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        );
      })}
    </Box>
  );
}

export default ItemsSkeleton;
