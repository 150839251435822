import React from "react";
// @mui-components
import { AppBar, Toolbar, IconButton, Button } from "@mui/material";
// @mui-icons
import MenuIcon from "@mui/icons-material/Menu";
import { ArrowBackIosNewRounded } from "@mui/icons-material";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @img
import logo from "../../img/logo.png";
function AppBarSidenav(props) {
  // @USE--react-router-dom
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    props.toggle();
  };

  // handle-is-back
  const handleIsBack = (value) => {
    props.activeSidenav(value);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${240}px)` },
        mr: { sm: `${240}px` },
        background: "#fff",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItms: "center",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ ml: 2, display: { sm: "none" }, color: "#1ca4c7" }}
        >
          <MenuIcon />
        </IconButton>

        <Button onClick={() => navigate("/")}>
          <img
            src={logo}
            alt="logo"
            style={{ height: "65px", width: "65px" }}
          />
        </Button>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => handleIsBack(1)}
          sx={{ color: "#1ca4c7" }}
        >
          <ArrowBackIosNewRounded />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarSidenav;
