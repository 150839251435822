// @Mui
import { Skeleton, Box, Paper } from "@mui/material";

function DashDisappearedsSkeleton() {
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <Box
      className="cards"
      sx={{ alignItems: "center", overflow: "hidden", pb: 2 }}
    >
      {arr.map((item) => {
        return (
          <Paper
            key={item}
            sx={{
              mt: 2,
              p: 2,
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: {
                xs: "column-reverse",
                sm: "column-reverse",
                md: "row",
              },
            }}
            elevation={2}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "100%", md: "30%" },
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Skeleton height={15} width="8%" />
              <Skeleton height={15} width="8%" />
              <Skeleton height={15} width="8%" />
              <Skeleton height={20} width="25%" />
            </Box>
            <Box
              sx={{
                pb: { xs: 2, sm: 2, md: 0 },
                width: { xs: "100%", sm: "100%", md: "40%" },
              }}
            >
              <Skeleton height={15} />
            </Box>
          </Paper>
        );
      })}
    </Box>
  );
}

export default DashDisappearedsSkeleton;
