// react
import { useState } from "react";
// component
import BackdropTemp from "./backdrop-temp";
// img
import share_feature from "../img/new-feature-share-fb.png";
// mui-components
import { Box, Typography, Button, Divider } from "@mui/material";
// mui-icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function NewFeaturePopup() {
  const [isOpen, setIsOpen] = useState(true);

  const handleClodeFeature = () => {
    setIsOpen(false);
  };

  return (
    <>
      <BackdropTemp isOpen={isOpen}></BackdropTemp>
      <Box
        dir="rtl"
        sx={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          borderRadius: "20px",
          height: "min-content",
          display: isOpen ? "flex" : "none",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          width: "300px",
          position: "fixed",
          transform: "translate(-50%, -50%)",
          left: "50%",
          top: "50%",
          zIndex: 10,
          transition: "width 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
          overflow: "hidden",
          bgcolor: "#fff",
        }}
      >
        <img
          style={{ height: "200px" }}
          src={share_feature}
          alt="new-feature-share-fb"
        />

        <Typography sx={{ color: "#1CA4C7", mt: 1, mb: 1 }} variant="h4">
          شارك على فيسبوك
        </Typography>
        <Divider sx={{ width: "80%" }} variant="middle" />
        <Typography
          sx={{
            color: "#1CA4C7",
            mt: 1,
            mb: 1,
            width: "80%",
            textAlign: "center",
          }}
        >
          يمكنك (ي) مشاركة معلومات عن المختفي(ة)، متشرد(ة) في صفحتك على فيسبوك
        </Typography>

        <Box
          sx={{
            backgroundColor: "#86D7EB",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            height: "5%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            pt: 1,
            pb: 1,
            minWidth: "100%",
          }}
        >
          <Button
            onClick={handleClodeFeature}
            sx={{ color: "#1CA4C7" }}
            variant="outlined"
            type="submit"
          >
            <ExpandMoreIcon />
            <Typography variant="h6" sx={{ mr: 1 }}>
              اخفاء
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
