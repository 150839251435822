//@Mui
import Grid from "@mui/material/Grid";
//@Fields
import FormField from "../form-field";

function newPasswordForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  let { password, confirmationPassword } = formField;
  let {
    password: passwordValue,
    confirmationPassword: confirmationPasswordValue,
  } = values;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormField
          type={password.type}
          label={password.label}
          name={password.name}
          value={passwordValue}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={(passwordValue.length > 0 && !errors.password).toString()}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          type={confirmationPassword.type}
          label={confirmationPassword.label}
          name={confirmationPassword.name}
          value={confirmationPasswordValue}
          placeholder={confirmationPassword.placeholder}
          error={errors.confirmationPassword && touched.confirmationPassword}
          success={(
            confirmationPasswordValue.length > 0 && !errors.confirmationPassword
          ).toString()}
        />
      </Grid>
    </Grid>
  );
}

export default newPasswordForm;
