//@Formik
import { ErrorMessage, Field } from "formik";
//@Mui
import { TextField, Box, Typography } from "@mui/material";

function FormField({ label, name, value, ...rest }) {
  return (
    <Box mb={1.5}>
      <Field
        key={name}
        {...rest}
        name={name}
        as={TextField}
        variant="outlined"
        label={label}
        fullWidth
        sx={{ textAlign: "right" }}
      />

      <Box mt={0.75} sx={{ display: "flex", justifyContent: "right", mr: 2 }}>
        <Typography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          sx={{ textTransform: "none" }}
        >
          <ErrorMessage name={name}>
            {(message) => (
              <div
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            )}
          </ErrorMessage>
        </Typography>
      </Box>
    </Box>
  );
}

export default FormField;
