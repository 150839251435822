// @React
import { useState } from "react";
// @Mui
import { Grid, ListItemButton, Button, IconButton, Box } from "@mui/material";
// @MUI-Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// @components
import Chips from "../chips";

export default function CollapseButton(props) {
  const { data } = props;
  const [selectedId, setSelectedId] = useState(null);

  const handleCollapseClicked = (value) => {
    setSelectedId(value);
    props.clickedId(value);
  };

  const handleDeleteDisappeared = (value) => {
    props.deleteId(value);
  };

  const handleEditDisappeared = (value) => {
    props.editId(value);
  };

  return (
    <>
      <ListItemButton
        key={data.id}
        sx={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
        }}
      >
        <Grid
          sx={{
            flexWrap: "nowrap",
            flexDirection: { xs: "column", sm: "column", md: "row-reverse" },
          }}
          container
          spacing={1}
        >
          <Grid item xs={12} sm={12}>
            <Button>
              {data.first_name === null ? "??" : data.first_name}{" "}
              {data.last_name === null ? "??" : data.last_name}{" "}
            </Button>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              alignContent: "center",
              justifyContent: {
                xs: "space-between",
                sm: "space-between",
                md: "right",
              },
            }}
            item
            xs={12}
            sm={12}
          >
            {data.id === selectedId ? (
              <IconButton
                onClick={() => handleCollapseClicked(null)}
                aria-label="ExpandLess"
              >
                <ExpandLess color="primary" />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => handleCollapseClicked(data.id)}
                aria-label="ExpandMore"
              >
                <ExpandMore color="primary" />
              </IconButton>
            )}

            <IconButton
              onClick={() => handleDeleteDisappeared(data.id)}
              aria-label="delete"
            >
              <HighlightOffIcon color="error" />
            </IconButton>
            <IconButton
              onClick={() => handleEditDisappeared(data.id)}
              sx={{ ml: 1 }}
              aria-label="edit"
            >
              <AutoFixHighIcon color="success" />
            </IconButton>
            <Chips
              size="small"
              label={data.type === "2" ? "متغيب(ة)" : "متشرد(ة)"}
              type={data.type ? "default" : "other"}
            />
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              alignContent: "center",
              pl: "8px",
              pt: "8px",
            }}
          >
            {data.is_found === "not" ? (
              <Chips size="small" type="default" label="مختفي(ة)" />
            ) : (
              <Chips size="small" type="found" label="وجد(ت)" />
            )}
          </Box>
        </Grid>
      </ListItemButton>
    </>
  );
}
