// icons-for-menu
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ForumIcon from "@mui/icons-material/Forum";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";

export const Genders = [
  { index: 1, label: "ذكر", value: 1 },
  { index: 2, label: "انثى", value: 2 },
];

export const typesDisappeared = [
  { index: 1, label: "متشرد(ة)", value: 1 },
  { index: 2, label: "متغيب(ة)", value: 2 },
];

export const isFoundOptions = [
  { index: 1, label: "مختفي(ة)", value: "not" },
  { index: 2, label: "وجد(ت)", value: "found" },
];

export const SidenavItems = [
  {
    id: 1,
    name: "قائمة المختفين",
    activeIcon: PersonSearchIcon,
    icon: PersonSearchOutlinedIcon,
    divider: "مختفون",
  },
  {
    id: 2,
    name: "اضافة مختفي",
    activeIcon: PersonAddAlt1Icon,
    icon: PersonAddAltIcon,
  },
  {
    id: 3,
    name: "التعليقات الخاصة بي",
    activeIcon: ForumIcon,
    icon: ForumOutlinedIcon,
    divider: "تعليقات",
  },
  {
    id: 5,
    name: "قائمة المستخدمين",
    activeIcon: PeopleIcon,
    icon: PeopleOutlineIcon,
    divider: "ادارة المستخدمين",
  },
  {
    id: 9,
    name: "تسجيل الخروج",
    activeIcon: LogoutIcon,
    icon: LogoutIcon,
  },
  {
    id: 10,
    name: "حذف الحساب",
    activeIcon: NoAccountsIcon,
    icon: NoAccountsIcon,
  },
];
