//react
import { useState } from "react";
//@Formik
import { ErrorMessage, Field, useFormikContext } from "formik";
//@Mui
import {
  TextField,
  Box,
  Typography,
  FormControl,
  Autocomplete,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  FormLabel,
} from "@mui/material";
//@MUI-Icons
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
// @components
import DestroyImage from "../../../../../components/destroy-image";
import ReadImgUrl from "../../../../../components/read-img-url";

function FormField({
  label,
  name,
  value,
  selectOptions,
  isGroupRadio,
  isAutoComplete,
  isImg,
  ...rest
}) {
  //@Fomik-Use
  const { setFieldValue } = useFormikContext();
  const [isChanged, setIsChanged] = useState(false);

  const destroyImg = async (img) => {
    setIsChanged(true);
    try {
      if (await DestroyImage(img)) {
      } else {
        //console.log("Error-Destroy-Image");
      }
    } catch (error) {
      //console.log("Error-Destroy-Image");
    }
  };

  return (
    <Box mb={1.5}>
      {isGroupRadio ? (
        <FormControl fullWidth>
          <FormLabel id="demo-controlled-radio-buttons-group">
            {label}
          </FormLabel>
          <RadioGroup
            {...rest}
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={(e) => setFieldValue(name, e.target.value)}
            defaultValue={`${value}`}
            value={`${value}`}
          >
            {selectOptions.map((option) => (
              <Grid key={option.index} item xs={6} sm={6}>
                <FormControlLabel
                  sx={{ display: "flex", flexDirection: "column-reverse" }}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              </Grid>
            ))}
          </RadioGroup>
        </FormControl>
      ) : isAutoComplete ? (
        <Autocomplete
          value={
            selectOptions.find((option) => option.value === value)?.value ||
            null
          }
          onChange={(event, newValue) => [
            setFieldValue(name, newValue?.value || ""),
          ]}
          key={selectOptions.id}
          options={selectOptions}
          {...rest}
          name={name}
          renderInput={(params) => (
            <TextField {...params} label={label} fullWidth />
          )}
        />
      ) : isImg ? (
        <>
          <Button
            variant="outlined"
            component="label"
            sx={{ overflow: "hidden", flexDirection: "column" }}
          >
            <ReadImgUrl isUploaded={isChanged} img={value} />

            <Box>
              <CloudDownloadRoundedIcon sx={{ ml: 2 }} />
              قم بتنزيل {label}
              <Field
                sx={{
                  width: 0,
                  maxHeight: 5,
                  padding: (0, 0),
                  margin: 0,
                  overflow: "hidden",
                }}
                InputProps={{
                  onChange: (e) => [
                    !isChanged ? destroyImg(value) : null,
                    setFieldValue(name, e.currentTarget.files[0]),
                  ],
                }}
                key={name}
                {...rest}
                name={name}
                value={undefined}
                as={TextField}
              />
            </Box>
          </Button>
        </>
      ) : (
        <Field
          // InputProps={{ inputProps: { min: 10, max: 20, step: 0.01 } }}
          // inputProps={{ min: 0, style: { textAlign: "right" } }} // the change is here
          key={name}
          {...rest}
          name={name}
          as={TextField}
          variant="outlined"
          label={label}
          fullWidth
          sx={{ textAlign: "right" }}
        />
      )}

      <Box mt={0.75} sx={{ display: "flex", justifyContent: "right", mr: 2 }}>
        <Typography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
        >
          {<ErrorMessage name={name} />}
        </Typography>
      </Box>
    </Box>
  );
}

export default FormField;
