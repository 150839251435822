// @moment
import moment from "moment";
// Import the Arabic locale
import "moment/locale/ar-ma";
// Import the moment-timezone library
import "moment-timezone";

function formatDateTime(dateTime) {
  const dateObj = new Date(dateTime);
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");

  const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedTimestamp;
}

const TimePassed = (datetime) => {
  // Set the locale to Arabic
  moment.locale("ar-ma");

  const format = "YYYY-MM-DD HH:mm:ss";

  // Set the time zone to "Africa/Casablanca"
  const casablancaTime = moment.tz(datetime, format, "Africa/Casablanca");

  const relativeTime = casablancaTime.fromNow();

  return relativeTime;
};

export default TimePassed;
