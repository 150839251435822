/* eslint-disable react-hooks/exhaustive-deps */

//@components
import CollapseButton from "./collapse-button";
import CollapseDetails from "./collapse-details";
import Paginations from "../pagination";
import NoItems from "../no-items";
import DashDisappearedsSkeleton from "../skeletons/dash-disappeareds-skeleton";
// @img
import search from "../../img/search.png";

export default function Collapse(props) {
  const {
    counterPages,
    disappeareds,
    comments,
    selectedId,
    isSubmittingCom,
    isSubmittingDisap,
  } = props;

  const handleCurrentPageChange = (value) => {
    props.currentPage(value);
  };

  const handleItemPerPageChange = (value) => {
    props.itemPerPage(value);
  };

  const handleClickedIdChange = (value) => {
    props.clickedId(value);
  };

  // delete-disappeared
  const handleDeleteDisappeared = (value) => {
    props.deleteId(value);
  };

  // edit-disappeared
  const handleEditDisappeared = (value) => {
    props.editId(value);
  };

  // edit-comment
  const handleEditComment = (value) => {
    props.editCommentId(value);
  };

  // delete-comment
  const handleDeleteComment = (value) => {
    props.deleteCommentId(value);
  };

  return (
    <>
      <Paginations
        counterPages={counterPages}
        itemPerPage={handleItemPerPageChange}
        currentPage={handleCurrentPageChange}
      />

      {isSubmittingDisap ? (
        <DashDisappearedsSkeleton />
      ) : disappeareds.length === 0 ? (
        <NoItems img={search} msg="لم تقم باضافي اي مختفي حاليا !" />
      ) : (
        disappeareds?.map((disp) => {
          return (
            <>
              <CollapseButton
                data={disp}
                // disappearedID
                editId={handleEditDisappeared}
                deleteId={handleDeleteDisappeared}
                clickedId={handleClickedIdChange}
              />
              <CollapseDetails
                clickedId={selectedId}
                disappearedId={disp.id}
                comments={comments}
                isSubmitting={isSubmittingCom}
                // CommentI
                deleteCommentId={handleDeleteComment}
                editCommentId={handleEditComment}
              />
            </>
          );
        })
      )}
    </>
  );
}
