//@Form
import checkout from "./form";

const {
  formField: {
    firstname,
    lastname,
    age,
    datedisappeared,
    gender,
    direction,
    province,
    otherProvince,
    city,
    otherCity,
    typedisappeared,
    phonenumber,
    imagedisappeared,
    is_found,
  },
} = checkout;

function getInitialValues(disappearedToUpdate) {
  return {
    [firstname.name]: disappearedToUpdate?.first_name,
    [lastname.name]: disappearedToUpdate?.last_name,
    [age.name]: disappearedToUpdate?.age,
    [datedisappeared.name]: disappearedToUpdate?.date_losted,
    [gender.name]: disappearedToUpdate?.gender,
    [direction.name]: disappearedToUpdate?.direction,
    [province.name]: disappearedToUpdate?.province,
    [otherProvince.name]:
      province === "آخرى" ? disappearedToUpdate?.province : "",
    [city.name]: disappearedToUpdate?.city,
    [otherCity.name]: city === "آخرى" ? disappearedToUpdate?.city : "",
    [typedisappeared.name]: disappearedToUpdate?.type,
    [phonenumber.name]: disappearedToUpdate?.phone,
    [imagedisappeared.name]: disappearedToUpdate?.picture,
    [is_found.name]: disappearedToUpdate?.is_found,
  };
}
export default getInitialValues;
