// Mui-Components
import { Chip } from "@mui/material";

export default function Chips({ type, label, size }) {
  return (
    <>
      {type === "default" ? (
        <Chip
          size={size === "small" ? "small" : "large"}
          sx={{
            color: "#1CA4C7",
            background: "#1ca4c759",
            fontSize: "medium",
          }}
          label={label}
        />
      ) : (
        <Chip
          size={size === "small" ? "small" : "large"}
          sx={{
            color: "#bef2ff",
            background: "#1ca4c7",
            fontSize: "medium",
          }}
          label={label}
        />
      )}
    </>
  );
}
