/* eslint-disable react-hooks/exhaustive-deps */
//@React
import { useEffect, useState } from "react";
//@MUI
import { Typography, Grid, IconButton, Button, Box } from "@mui/material";
//@MUI-Icons
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
//@Pages
import BackdropTemp from "../../../../../components/backdrop-temp";
// @Formik
import { Form, Formik } from "formik";
// @components-formik
import FiltrationForm from "../filtration/components/FiltrationForm";
import initialValues from "../filtration/components/Shemas/initialValues";
import form from "../filtration/components/Shemas/form";
import validations from "../filtration/components/Shemas/validations";
// @Options
import {
  Genders,
  typesDisappeared,
  isFoundOptions,
} from "../../../../../utils/select-options";
import cities from "../../../../../utils//files-json/Cities-Maroc.json";
import directions from "../../../../../utils//files-json/Directions-Maroc.json";
import provinces from "../../../../../utils//files-json/Provinces-Maroc.json";

function Filtration(props) {
  const [isFiltrationOpen, setisFiltrationOpen] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // use @Formik For New User
  const { formId, formField } = form;
  const currentValidation = validations[0];

  const checkIsFiltrationFilledAtLeastOne = async (values) => {
    const isAtLeastOneFilled = await Object.values(values).some(
      (value) => value !== ""
    );

    return isAtLeastOneFilled;
  };

  const submitFiltration = async (values, actions) => {
    setIsSubmitting(true);

    const {
      first_name,
      last_name,
      age,
      gender,
      direction,
      province,
      city,
      date_disappeared,
      type_disappeared,
      is_found,
    } = values;

    const filtration = {
      first_name: first_name,
      last_name: last_name,
      age: age,
      gender: gender === "" ? "" : gender,
      direction: direction,
      province: province,
      city: city,
      date_disappeared: date_disappeared,
      type_disappeared: type_disappeared === "" ? "" : type_disappeared,
      is_found: is_found,
    };

    props.onChange(filtration);
    handleIsFiltringChange(true);
    setisFiltrationOpen(!isFiltrationOpen);
    setIsSubmitting(false);
  };

  const handleSubmit = async (values, actions) => {
    if (await checkIsFiltrationFilledAtLeastOne(values)) {
      submitFiltration(values, actions);
    }
    actions.setTouched({});
    setIsSubmitting(false);
  };

  const handleIsFiltringChange = (value) => {
    setIsFiltering(value);
  };

  function resetFiltration() {
    props.onChange({
      first_name: "",
      last_name: "",
      age: "",
      gender: "",
      direction: "",
      province: "",
      city: "",
      date_disappeared: "",
      type_disappeared: "",
      is_found: "",
    });
    handleIsFiltringChange(false);
  }

  useEffect(() => {
    resetFiltration();
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        lg={3}
        className="dashboard_filtration"
        sx={{
          alignItems: { xs: "center", sm: "center", md: "start" },
          zIndex: isFiltrationOpen ? 10 : 8,
          width: {
            xs: isFiltrationOpen ? "90%" : "0%",
            md: isFiltrationOpen ? "90%" : "0%",
            lg: "90%",
          },
        }}
      >
        <Box
          dir="rtl"
          className={isFiltrationOpen ? "position_md" : "position_stickly"}
          sx={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            borderRadius: "20px",
            width: "100%",
            height: "min-content",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              resetForm,
            }) => (
              <Form id={formId} autoComplete="off">
                <Box
                  sx={{
                    backgroundColor: "#86D7EB",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    height: "5%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    pt: 1,
                    pb: 1,
                  }}
                >
                  {isFiltrationOpen && (
                    <IconButton
                      onClick={() => setisFiltrationOpen(false)}
                      sx={{ mr: 2 }}
                    >
                      <CancelOutlinedIcon />
                    </IconButton>
                  )}
                  {isFiltering ? (
                    <Button
                      sx={{ color: "#1CA4C7" }}
                      variant="outlined"
                      disabled={isSubmitting}
                      onClick={() => [
                        resetForm(),
                        resetFiltration(),
                        setisFiltrationOpen(false),
                      ]}
                    >
                      <FilterListOffIcon />
                      <Typography sx={{ ml: 1 }}>مسح الترشيح </Typography>
                    </Button>
                  ) : (
                    <Typography sx={{ ml: 1 }}>ترشيح </Typography>
                  )}
                </Box>
                <Box
                  className="scroll_filtration"
                  sx={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    backgroundColor: "#fff",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: { xs: "70vh", sm: "70vh", md: "50vh" },
                    width: "100%",
                  }}
                >
                  <FiltrationForm
                    formData={{
                      values,
                      touched,
                      formField,
                      errors,
                      handleChange,
                      handleBlur,
                      Genders,
                      typesDisappeared,
                      directions,
                      provinces,
                      cities,
                      isFoundOptions,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#86D7EB",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                    height: "5%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    pt: 1,
                    pb: 1,
                  }}
                >
                  <Button
                    sx={{ color: "#1CA4C7" }}
                    variant="outlined"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    <FilterListOutlinedIcon />
                    <Typography sx={{ ml: 1 }}>ترشيح</Typography>
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Grid>
      {/* Icon Of Filtration in Mobile */}
      <IconButton
        className="dashboard_filtration_icon"
        onClick={() => setisFiltrationOpen(!isFiltrationOpen)}
        sx={{
          zIndex: 5,
          border: "2px solid #1ca4c7",
          borderColor: "text",
          p: "auto",
          bgcolor: "common.white",
          display: { xs: "flex", md: "flex", lg: "none" },
          fontWeight: "bold",
        }}
        title="ترشيح"
      >
        <FilterListOutlinedIcon sx={{ color: "#1ca4c7" }} fontSize="large" />
      </IconButton>

      <BackdropTemp isOpen={isFiltrationOpen} />
    </>
  );
}

export default Filtration;
