// @Css-File
import "./App.css";
// @react-router-dom
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// @Pages
import Error from "./layouts/error";
import Header from "./layouts/header";
import Cover from "./layouts/cover";
import NewFeaturePopup from "./components/new-feature-popup";
import Footer from "./layouts/footer";
// import VideoCard from "./layouts/video-card";
import ListingDisappeared from "./layouts/disappeared/listing-disappeared";
import SignIn from "./layouts/auth/sign-in";
import SignUp from "./layouts/auth/sign-up";
import ResetPassword from "./layouts/auth/reset-password";
import NewPassword from "./layouts/auth/new-password";
import NewComment from "./layouts/comment/new-comment";
import SomePeople from "./layouts/some-people";
// facebook
import Sidenav from "./components/sidenav/sidenav";
// Routes-Pages
// 1- /auth/sign-in
// 2- /auth/sign-up
// 3- /auth/reset-password
// 3-1 /auth/new-password/:generateLink
// ****Disappeared
// 4- /new-disappeared
// 5- /edit-disappeared
// 6- /disappeared
// ****Comment
// 7- /new-comment
// ***Dashboard
// 8- /dashboard

function App() {
  return (
    <>
      <div className="background_black"></div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Cover />
                <NewFeaturePopup />
                {/* <VideoCard /> */}
                <SomePeople />
                <Footer />
              </>
            }
          />
          <Route
            path="/auth/sign-in"
            element={
              <>
                <SignIn />
              </>
            }
          />
          <Route
            path="/auth/sign-up"
            element={
              <>
                <SignUp />
              </>
            }
          />
          <Route
            path="/auth/reset-password"
            element={
              <>
                <ResetPassword />
              </>
            }
          />
          <Route
            path="/auth/new-password/:generateLink"
            element={
              <>
                <NewPassword />
              </>
            }
          />
          <Route
            path="/disappeared"
            element={
              <>
                <Header />
                <ListingDisappeared />
                <Footer />
              </>
            }
          />
          <Route
            path="/new-comment"
            element={
              <>
                <Header />
                <NewComment />
                <Footer />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <Sidenav />
              </>
            }
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
