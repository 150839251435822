//@Mui-components
import { Typography, Collapse } from "@mui/material";
// @components
import CommentBody from "../comment-popup/components/comment-body";

export default function CollapseDetails(props) {
  const { comments, disappearedId, clickedId, isSubmitting } = props;

  // edit-comment
  const handleEditComment = (value) => {
    props.editCommentId(value);
  };

  // delete-comment
  const handleDeleteComment = (value) => {
    props.deleteCommentId(value);
  };
  return (
    <>
      <Collapse
        sx={{ pb: 5 }}
        in={clickedId === disappearedId}
        timeout="auto"
        unmountOnExit
      >
        <Typography sx={{ m: 2 }} variant="h5" gutterBottom>
          التعليقات
        </Typography>

        <CommentBody
          isSubmitting={isSubmitting}
          comments={comments}
          inDash={true}
          // commentID
          editCommentId={handleEditComment}
          deleteCommentId={handleDeleteComment}
        />
      </Collapse>
    </>
  );
}
