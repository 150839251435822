/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */

import * as React from "react";
// @Mui
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";
// @Mui-Icon
import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

export default function SimpleSnackbar(props) {
  const { vertical, horizental, message, typeAlert, isOpen, handleClose } = props;

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{
        vertical: vertical === undefined ? "bottom" : vertical,
        horizontal: horizental === undefined ? "right" : horizental,
      }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      action={action}
    >
      <Alert onClose={handleClose} severity={typeAlert} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
