/* eslint-disable jsx-a11y/anchor-is-valid */

// @react
import { useState, useEffect } from "react";
// @js-cookie
import Cookies from "js-cookie";
// @css
import "./index.css";
// @Formik
import { Form, Formik } from "formik";
// @Formik-Components
import SignInForm from "./components/signin-form";
import form from "./components/schemas/form";
import validations from "./components/schemas/validations";
import initialValues from "./components/schemas/initiale-values";
// @mui
import { Card, Box, Button } from "@mui/material";
// @snackbar
import Snackbar from "../../../components/Snackbar";
// @api
import { Auth } from "../../../utils/fetcher-api";
// @crypto-js
import CryptoJS from "crypto-js";
// @react-router-dom
import { useNavigate } from "react-router-dom";

function SignIn() {
  // use-@react-router-dom
  const navigate = useNavigate();

  // use @Formik For New User
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [IsSubmitting, setIsSubmitting] = useState(false);

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // encrypt-token
  function encryptToken(token) {
    // random encryption key
    const key = "8yNTw%12tLxG$!D7";

    // I-Crypt
    const encryptedToken = CryptoJS.AES.encrypt(token, key).toString();

    Cookies.set("token", encryptedToken);
  }

  // handle-response-&-errors
  async function handleResponseErrors(response) {
    if (response.status) {
      if (
        response.data.details_response_token.access_token !== undefined ||
        response.data.details_response_token.access_token !== ""
      ) {
        // encryptToken
        encryptToken(response.data.details_response_token.access_token);

        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("! أهلا من جديد");

        await sleep(1000);
        navigate("/dashboard");
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! البريد الإلكتروني أو كلمة المرور غير صحيحة");
      } else if (response.code === 422) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! المعطيات غير صالحة");
      }
    }
    setIsSubmitting(false);
  }

  // submit-SignIn
  const submitSignIn = async (values, actions) => {
    actions.setSubmitting(true);
    setIsSubmitting(true);
    await sleep(1000);

    const { email, password } = values;

    const signIn = {
      email: email,
      password: password,
    };

    try {
      const response = await Auth.login(signIn);

      // handle-response-&-errors
      await handleResponseErrors(response);

      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage(".خطأ، حاول مرة أخرى");
    }
  };

  const handleSubmit = (values, actions) => {
    setIsSubmitting(true);
    submitSignIn(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
    setIsSubmitting(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    document.title = "مختفون | تسجيل الدخول";
  });

  return (
    <>
      <Box className="box">
        <section className="section">
          <h1>تسجيل الدخول</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              resetForm,
            }) => (
              <Form
                dir="rtl"
                id={formId}
                autoComplete="off"
                style={{
                  pointerEvents: IsSubmitting ? "none" : "painted",
                  opacity: IsSubmitting ? 0.4 : 1,
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    background: "transparent",
                  }}
                >
                  <Box
                    color="white"
                    background="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    sx={{ textTransform: "uppercase" }}
                  >
                    <Box p={2}>
                      <Box sx={{ pt: 3 }}>
                        <SignInForm
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            handleChange,
                            handleBlur,
                          }}
                        />
                      </Box>

                      <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Button
                          className="button"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="info"
                        >
                          {IsSubmitting ? "..." : "تسجيل الدخول"}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>

          <div className="register">
            <p>
              ليس لديك حساب{" "}
              <a
                onClick={(e) => [e.preventDefault(), navigate("/auth/sign-up")]}
                href="#"
              >
                تسجل الان
              </a>
            </p>
            <p>
              نسيت كلمة السر{" "}
              <a
                onClick={(e) => [
                  e.preventDefault(),
                  navigate("/auth/reset-password"),
                ]}
                href="#"
              >
                استرجعها الآن
              </a>
            </p>
          </div>
        </section>
      </Box>
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
    </>
  );
}

export default SignIn;
