const form = {
  formId: "reset-password",
  formField: {
    email: {
      name: "email",
      label: "البريد الالكتروني",
      type: "email",
      errorMsg: "البريد الالكتروني مطلوب.",
      minValue: "",
      invalidMsg: "البريد الالكتروني غير صالح.",
      placeholder: "الرجاء إدخال البريد الالكتروني",
    },
  },
};

export default form;
