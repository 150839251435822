/* eslint-disable react-hooks/exhaustive-deps */

// @react
import { useEffect, useState } from "react";
// @components
import Paginations from "../../components/pagination";
import CommentBody from "../../components/comment-popup/components/comment-body";
import SnackBar from "../../components/Snackbar";
import AlertDialog from "../../components/alert-dialog";
// @API
import { User, Comment } from "../../utils/fetcher-api";
// @react-router-dom
import { useNavigate } from "react-router-dom";

function ListingUserComments(props) {
  // use-@react-router-dom
  const navigate = useNavigate();
  const [counterPages, setCounterPages] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comments, setComments] = useState([]);
  // pagination
  const [itemPerPage, setItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  // alert-dialog
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState(null);
  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  // pagination
  const handleCurrentPageChange = (value) => {
    setCurrentPage(value);
  };
  const handleItemPerPageChange = (value) => {
    setItemPerPage(value);
    setCurrentPage(1);
  };

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // dialog-confirmation
  const handleOpenDialogChange = (value, type) => {
    setSelectedIdToDelete(value);
    setIsOpenDialog(true);
  };
  const handleDeleteConfirmationChange = async (value) => {
    if (value) await deleteComment(selectedIdToDelete);

    setIsOpenDialog(false);
  };
  // actions-comment
  const handleEditComment = (value) => {
    props.editCommentId(value);
  };

  const deleteComment = async (value) => {
    setIsSubmitting(true);

    try {
      const response = await Comment.delete(value);
      await sleep(1000);

      if (await handleResponseErrorsForDeleteCom(response)) {
        await getUserComment();
      }
    } catch (error) {
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("خطا..حاول مرة اخرى !");
    }
  };

  const handleClickedIdChange = (value) => {
    setSelectedIdToDelete(value);
    handleOpenDialogChange(value);
  };

  // handle-errors-responses
  async function handleResponseErrorsForGetComm(response) {
    if (response.status) {
      if (response.code === 200) {
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 404) {
        setComments([]);
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("لم تقم باضافي اي تعليق حاليا ! !");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      }
    }
    return false;
  }
  async function handleResponseErrorsForDeleteCom(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("تم حذف التعليق بنجاح !");
        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      } else if (response.code === 404) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("ليس هناك اي تعليق للحذف !");
      }
    }
    return false;
  }

  // get-user-comments
  const getUserComment = async () => {
    setIsSubmitting(true);

    try {
      const form = {
        itemPerPage: itemPerPage,
        currentPage: currentPage,
      };
      const response = await User.comments(form);
      await sleep(1000);
      if (await handleResponseErrorsForGetComm(response)) {
        setComments(Object.values(response.data.data));
        setCounterPages(response?.data.last_page);
      }

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("! حدث خطا..حاول مرة اخرى");
    }
  };
  useEffect(() => {
    getUserComment();
  }, [itemPerPage, currentPage]);

  useEffect(() => {
    document.title = "مختفون | التعليقات الخاصة بي";
  });

  return (
    <>
      <Paginations
        counterPages={counterPages}
        itemPerPage={handleItemPerPageChange}
        currentPage={handleCurrentPageChange}
      />
      <CommentBody
        isSubmitting={isSubmitting}
        comments={comments}
        inDash={true}
        // commentID
        editCommentId={handleEditComment}
        deleteCommentId={handleClickedIdChange}
      />
      <SnackBar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
      <AlertDialog
        confirmation={handleDeleteConfirmationChange}
        isOpen={isOpenDialog}
      />
    </>
  );
}

export default ListingUserComments;
