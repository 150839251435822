/* eslint-disable no-unused-vars */

//@Yup
import * as Yup from "yup";
//@Form
import checkout from "./form";
//@Regular-Expression
import {
  phoneRegExp,
  FileSize_IMG,
  SUPPORTED_FORMATS_IMG,
} from "../../../../../utils/RegExp";

function isImage(ImageType, SUPPORTED_FORMATS) {
  return SUPPORTED_FORMATS.includes(ImageType);
}

const checkTypeDisappeared = () => {};

const {
  formField: {
    firstname,
    lastname,
    phonenumber,
    age,
    datedisappeared,
    gender,
    direction,
    province,
    otherProvince,
    city,
    otherCity,
    typedisappeared,
    imagedisappeared,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [typedisappeared.name]: Yup.mixed().required(typedisappeared.errorMsg),

    [firstname.name]: Yup.string().when("typedisappeared", {
      is: (type) => type === "2",
      then: (schema) =>
        schema.required(firstname.errorMsg).min(4, firstname.minValue),
      otherwise: (schema) => schema.notRequired(),
    }),

    [lastname.name]: Yup.string().when("typedisappeared", {
      is: (type) => type === "2",
      then: (schema) =>
        schema.required(lastname.errorMsg).min(4, lastname.minValue),
      otherwise: (schema) => schema.notRequired(),
    }),

    [age.name]: Yup.string().when("typedisappeared", {
      is: (type) => type === "2",
      then: (schema) => schema.required(age.errorMsg).min(2, age.minValue),
      otherwise: (schema) => schema.notRequired(),
    }),

    [gender.name]: Yup.string().required(gender.errorMsg),

    [datedisappeared.name]: Yup.date(),

    [direction.name]: Yup.string().required(direction.errorMsg),

    [province.name]: Yup.string().required(province.errorMsg),
    [otherProvince.name]: Yup.string().when("province", {
      is: (province) => province === "آخرى",
      then: (schema) => schema.required(otherProvince.errorMsg),
      otherwise: (schema) => schema.notRequired(),
    }),

    [city.name]: Yup.string().required(city.errorMsg),
    [otherCity.name]: Yup.string().when("city", {
      is: (province) => province === "آخرى",
      then: (schema) => schema.required(otherCity.errorMsg),
      otherwise: (schema) => schema.notRequired(),
    }),

    [phonenumber.name]: Yup.string().when("typedisappeared", {
      is: (type) => type === "2",
      then: (schema) =>
        schema
          .min(10, phonenumber.minValue)
          .required(phonenumber.errorMsg)
          .matches(phoneRegExp, phonenumber.invalidMsg),
      otherwise: (schema) => schema.notRequired(),
    }),

    [imagedisappeared.name]: Yup.mixed()
      .required(imagedisappeared.errorMsg)
      .test("type", imagedisappeared.fileTypes, (value) => {
        return value && isImage(value.type, SUPPORTED_FORMATS_IMG);
      })
      .test(
        "fileSize",
        imagedisappeared.maxSize + FileSize_IMG / 1000000 + "Mo.",
        (value) => {
          return value && value.size <= FileSize_IMG;
        }
      ),
  }),
];

export default validations;
