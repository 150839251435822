// @rect
import { forwardRef } from "react";
// @mui-components
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog(props) {
  const { isOpen, name } = props;

  const handleConfirmation = () => {
    props.confirmation(true);
    handleClose();
  };

  const handleClose = () => {
    props.confirmation(false);
  };

  return (
    <Box>
      <Dialog
        dir="rtl"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          هل تريد فعلا حذف <strong> {name ? "الحساب" : "العنصر"}</strong>؟
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            سيتم حذف <strong> {name ? "الحساب" : "العنصر"}</strong> بشكل نهائي.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ ml: 2 }} variant="outlined" onClick={handleClose}>
            الغاء
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmation}
          >
            حذف
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
