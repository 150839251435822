// @react
import { useEffect, useState } from "react";
// @mui-components
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Toolbar,
  Typography,
  Drawer,
} from "@mui/material";
// @components
import userStatus from "../userStatus";

function DrawerSidenav(props) {
  const { window, SidenavItems, isMobileOpen, activeSidenaveItemD } = props;
  const { userData } = userStatus(true);

  const [activeSidenaveItem, setActiveSidenaveItem] = useState(1);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    props.toggle();
  };

  const handleActiveSidenavChange = (value) => {
    setActiveSidenaveItem(value);
    props.activeSidenav(value);
  };

  const drawer = (
    <Box>
      <Toolbar sx={{ justifyContent: "center" }}>
        <Typography
          sx={{ color: "#1CA4C7" }}
          fontWeight="bold"
          variant="h6"
          noWrap
          component="div"
        >
          {"- " + userData?.name + " -"}
        </Typography>
      </Toolbar>
      <List>
        {SidenavItems.map((item) =>
          item.id === 5 && userData?.user_type === "member" ? (
            <></>
          ) : (
            <>
              {item.id % 2 !== 0 && (
                <Divider
                  sx={{
                    color:
                      activeSidenaveItem === item.id ||
                      activeSidenaveItem === item.id + 1
                        ? "#1CA4C7"
                        : "inherit",
                    transition: "all 0.4s",
                  }}
                  textAlign="center"
                >
                  {item.divider}
                </Divider>
              )}
              <ListItem
                sx={{
                  background:
                    activeSidenaveItem === item.id ? "#86D7EB" : "#00000000",
                  color: activeSidenaveItem === item.id ? "#fff" : "inherit",
                  transition: "all 0.5s",
                }}
                onClick={() => [
                  handleDrawerToggle(),
                  handleActiveSidenavChange(item.id),
                ]}
                key={item.id}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    {activeSidenaveItem === item.id ? (
                      <item.activeIcon
                        sx={{
                          color:
                            activeSidenaveItem === item.id
                              ? "#1CA4C7"
                              : "inherit",
                        }}
                      />
                    ) : (
                      <item.icon
                        sx={{
                          color:
                            activeSidenaveItem === item.id
                              ? "#1CA4C7"
                              : "inherit",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    className="sidenav_text"
                    sx={{
                      color:
                        activeSidenaveItem === item.id ? "#1CA4C7" : "inherit",
                    }}
                    primary={item.name}
                  />
                  <ListItemText></ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          )
        )}
      </List>
    </Box>
  );

  useEffect(() => {
    setActiveSidenaveItem(activeSidenaveItemD);
  }, [activeSidenaveItemD]);

  useEffect(() => {
    setActiveSidenaveItem(1);
  }, []);

  return (
    <Box
      component="nav"
      sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        dir="rtl"
        anchor="right"
        container={container}
        variant="temporary"
        open={isMobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        anchor="right"
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 240,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default DrawerSidenav;
