// @form
import checkout from "./form";

const {
  formField: { fullName, email, password },
} = checkout;

const initialValues = {
  [email.name]: "",
  [password.name]: "",
  [fullName.name]: "",
};

export default initialValues;
