/* eslint-disable react-hooks/rules-of-hooks */

// @react
import { useEffect, useState } from "react";
// @API
import { pathToImg } from "../utils/fetcher-api";
// @img
import persone from "../img/persone.png";

function ReadImgUrl({ img, isUploaded }) {
  const [imgUrl, setImgUrl] = useState(persone);

  function readURL(img) {
    if (img.type !== undefined) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setImgUrl(e.target.result);
      };

      reader.readAsDataURL(img);
    }
  }

  useEffect(() => {
    isUploaded ? readURL(img) : setImgUrl(img);
  }, [img, isUploaded]);

  return (
    <img
      style={{
        height: "200px",
        width: "200px",
        borderRadius: "10px",
        mt: 2,
        mb: 2,
      }}
      src={isUploaded ? imgUrl : pathToImg + imgUrl}
      alt="صورة المختفي(ة)"
    />
  );
}

export default ReadImgUrl;
