/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

// @React
import { useEffect, useState } from "react";
// @MUI
import { Typography, IconButton, Button, Box } from "@mui/material";
// @MUI-Icons
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @Components
import userStatus from "../userStatus";
import BackdropTemp from "../backdrop-temp";
import CommentBody from "./components/comment-body";
// @API
import { Disappeared } from "../../utils/fetcher-api";

function CommentPopup(props) {
  let { commentStatus } = props;

  // use-@react-router-dom
  const navigate = useNavigate();

  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [comments, setComments] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // user-status
  const [canRedirect, setCanRedirect] = useState(false);
  const { isAuthenticated } = userStatus(canRedirect);

  // sleeper
  const sleep = (ms) => {
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  // get-comment-by-ID
  const getComments = async (id) => {
    try {
      setIsSubmitting(true);
      const response = await Disappeared.comments(id);
      await sleep(1000);
      if (response.status && response.code === 200) {
        setComments(response.data.data);
      } else {
        setComments("");
      }
      await sleep(2000);
      setIsSubmitting(false);
    } catch (error) {
      setComments("");
      setIsSubmitting(false);
    }
  };

  // if-commentStatus-changed
  useEffect(() => {
    setIsCommentOpen(commentStatus.status);
  }, [commentStatus.disappearedId, commentStatus.status]);

  // if-disappearedId-changed
  useEffect(() => {
    if (commentStatus.disappearedId !== null) {
      getComments(commentStatus.disappearedId);
    } else {
      setComments("");
    }
  }, [commentStatus]);

  const handleCloseComment = () => {
    const status = {
      disappearedId: null,
      status: false,
    };

    setIsCommentOpen(false);
    props.commentClosed(status);
  };

  const handleAddComment = () => {
    setCanRedirect(true);

    if (isAuthenticated) {
      navigate("/new-comment", {
        state: { disappearedId: commentStatus.disappearedId },
      });
    } else {
      navigate("/auth/sign-in");
    }
  };

  return (
    <>
      <Box
        dir="rtl"
        sx={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          borderRadius: "20px",
          height: "min-content",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          width: isCommentOpen ? "300px" : "0px",
          position: "fixed",
          transform: "translate(-50%, -50%)",
          left: "50%",
          top: "50%",
          zIndex: 10,
          transition: "width 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#86D7EB",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            height: "5%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            pt: 1,
            pb: 1,
            minWidth: "100%",
          }}
        >
          <IconButton onClick={handleCloseComment} sx={{ mr: 2 }}>
            <CancelOutlinedIcon />
          </IconButton>

          <Typography variant="h6" gutterBottom sx={{ ml: 1 }}>
            ماذا يقول الناس عن المختفي ؟{" "}
          </Typography>
        </Box>
        <CommentBody
          isSubmitting={isSubmitting}
          comments={comments}
          inDash={false}
        />
        <Box
          sx={{
            backgroundColor: "#86D7EB",
            borderBottomLeftRadius: "20px",
            borderBottomRightRadius: "20px",
            height: "5%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            pt: 1,
            pb: 1,
            minWidth: "100%",
          }}
        >
          <Button
            onClick={handleAddComment}
            sx={{ color: "#1CA4C7" }}
            variant="outlined"
            type="submit"
          >
            <SettingsVoiceIcon />
            <Typography variant="h6" sx={{ ml: 1 }}>
              اضف تعليقا
            </Typography>
          </Button>
        </Box>
      </Box>

      <BackdropTemp isOpen={isCommentOpen} />
    </>
  );
}

export default CommentPopup;
