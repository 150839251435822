//@Mui
import Grid from "@mui/material/Grid";
//@Fields
import FormField from "../FormField";

function NewCommentForm({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    directions,
    provinces,
    cities,
  } = formData;

  let { comment, direction, province, otherProvince, city, otherCity } =
    formField;

  let {
    comment: commentValue,
    direction: directionValue,
    province: provinceValue,
    otherProvince: otherProvinceValue,
    city: cityValue,
    otherCity: otherCityValue,
  } = values;

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          pb: 7,
          p: 3,
        }}
      >
        <Grid item xs={12} sm={12}>
          <FormField
            type={comment.type}
            label={comment.label}
            name={comment.name}
            value={commentValue}
            error={(errors.comment && touched.comment)?.toString()}
            success={(commentValue?.length > 0 && !errors.comment).toString()}
            placeholder={comment.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={directions}
            isAutoComplete
            type={direction.type}
            label={direction.label}
            name={direction.name}
            value={directionValue}
            error={(errors.direction && touched.direction)?.toString()}
            success={(
              directionValue?.length > 0 && !errors.direction
            ).toString()}
            placeholder={direction.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={provinces.filter(
              (p) => p.direction === directionValue || p.direction === "آخرى"
            )}
            isAutoComplete
            type={province.type}
            label={province.label}
            name={province.name}
            value={provinceValue}
            error={(errors.province && touched.province)?.toString()}
            success={(provinceValue?.length > 0 && !errors.province).toString()}
            placeholder={province.placeholder}
          />
        </Grid>
        {provinceValue === "آخرى" && (
          <Grid item xs={12} sm={6}>
            <FormField
              type={otherProvince.type}
              label={otherProvince.label}
              name={otherProvince.name}
              value={otherProvinceValue}
              placeholder={otherProvince.placeholder}
              error={errors.otherProvince && touched.otherProvince}
              success={(
                otherProvinceValue.length > 0 && !errors.otherProvince
              ).toString()}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={cities.filter((c) => c.province === provinceValue)}
            isAutoComplete
            type={city.type}
            label={city.label}
            name={city.name}
            value={cityValue}
            error={(errors.city && touched.city)?.toString()}
            success={(cityValue?.length > 0 && !errors.city).toString()}
            placeholder={city.placeholder}
          />
        </Grid>
        {cityValue === "آخرى" && (
          <Grid item xs={12} sm={6}>
            <FormField
              type={otherCity.type}
              label={otherCity.label}
              name={otherCity.name}
              value={otherCityValue}
              placeholder={otherCity.placeholder}
              error={errors.otherCity && touched.otherCity}
              success={(
                otherCityValue.length > 0 && !errors.otherCity
              ).toString()}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default NewCommentForm;
