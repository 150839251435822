/* eslint-disable array-callback-return */

// @Css-File
import "./index.css";
// @react
import React from "react";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @Mui-Components
import { Button, Box } from "@mui/material";
// @Mui-Icons
import ReadMoreIcon from "@mui/icons-material/ReadMore";
// @Pages
import SectionTitle from "../section-title";
// @Components
import CardDisappeared from "../card-disappeared";
import CardTramp from "../card-tramp";

function CategoryPeople(props) {
  const { title, subTitle, redirect, type, data } = props;
  // @USE--react-router-dom
  const navigate = useNavigate();
  return (
    <>
      <SectionTitle title={title} subTitle={subTitle} />

      <Box className="category_cards">
        {type === "disappeared"
          ? data.map((d) => {
              return <CardDisappeared inHome key={data.id} data={d} />;
            })
          : data.map((d) => {
              return <CardTramp inHome key={data.id} data={d} />;
            })}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 2 }}>
        <Button
          onClick={() => navigate(`${redirect}`)}
          variant="outlined"
          startIcon={<ReadMoreIcon />}
        >
          تعرف على المزيد
        </Button>
      </Box>
    </>
  );
}

export default CategoryPeople;
