/* eslint-disable jsx-a11y/anchor-is-valid */

// @react
import { useState, useEffect } from "react";
// @css
import "./index.css";
// @Formik
import { Form, Formik } from "formik";
// @Formik-Components
import SignUpForm from "./components/signup-form";
import form from "./components/schemas/form";
import validations from "./components/schemas/validations";
import initialValues from "./components/schemas/initiale-values";
// @mui
import { Card, Box, Button } from "@mui/material";
// @snackbar
import Snackbar from "../../../components/Snackbar";
// @api
import { Auth } from "../../../utils/fetcher-api";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// get-Current-Date-time
import { getCurrentDateTime } from "../../../components/get-current-date-time";

function SignUp() {
  // use-@react-router-dom
  const navigate = useNavigate();

  // use @Formik For New User
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [IsSubmitting, setIsSubmitting] = useState(false);

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // handle-response-&-errors
  async function handleResponseErrors(response) {
    if (response.status) {
      if (response.code === 201) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("! ثم انشاء الحساب بنجاح");

        await sleep(1000);
        navigate("/auth/sign-in");
      }
    } else {
      if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage(".خطأ، حاول مرة أخرى");
      } else if (response.code === 409) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! البريد الإلكتروني مستعمل من قبل");
      } else if (response.code === 422) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! المعطيات غير صالحة");
      }
    }
    setIsSubmitting(false);
  }

  // submit-newUser
  const submitNewUser = async (values, actions) => {
    actions.setSubmitting(true);
    setIsSubmitting(true);
    await sleep(1000);

    const { fullName, email, password } = values;
    const currentDateTime = await getCurrentDateTime();

    const newUser = {
      user_type: "member",
      name: fullName,
      email: email,
      password: password,
      created_at: currentDateTime,
      updated_at: currentDateTime,
    };

    try {
      const response = await Auth.register(newUser);

      // handle-response-&-errors
      await handleResponseErrors(response);

      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage(".خطأ، حاول مرة أخرى");
    }
  };

  const handleSubmit = (values, actions) => {
    setIsSubmitting(true);
    submitNewUser(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
    setIsSubmitting(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    document.title = "مختفون | انشاء حساب جديد ";
  });

  return (
    <>
      <Box className="box">
        <section className="section">
          <h1>انشاء حساب</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              resetForm,
            }) => (
              <Form
                dir="rtl"
                id={formId}
                autoComplete="off"
                style={{
                  pointerEvents: IsSubmitting ? "none" : "painted",
                  opacity: IsSubmitting ? 0.4 : 1,
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                    background: "transparent",
                  }}
                >
                  <Box
                    color="white"
                    background="info"
                    variant="gradient"
                    borderRadius="lg"
                    shadow="lg"
                    opacity={1}
                    sx={{ textTransform: "uppercase" }}
                  >
                    <Box p={2}>
                      <Box sx={{ pt: 3 }}>
                        <SignUpForm
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            handleChange,
                            handleBlur,
                          }}
                        />
                      </Box>

                      <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Button
                          className="button"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="info"
                        >
                          {IsSubmitting ? "..." : "انشاء حساب جديد"}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Form>
            )}
          </Formik>

          <div className="register">
            <p>
              لديك حساب{" "}
              <a
                onClick={(e) => [e.preventDefault(), navigate("/auth/sign-in")]}
                href="#"
              >
                سجل دخول
              </a>
            </p>
          </div>
        </section>
      </Box>
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
    </>
  );
}

export default SignUp;
