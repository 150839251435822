// @react
import { useState } from "react";
// @mui-components
import { Toolbar, Box } from "@mui/material";
// @components
import NewDisappeared from "../../layouts/disappeared/new-disappeared";
import EditDisappeared from "../../layouts/disappeared/edit-disappeared";
import ListingDisappearedWithComments from "../../layouts/dashboard/listing-disappeared-with-comments";
import EditComment from "../../layouts/comment/edit-comment";
import ListingUserComments from "../../layouts/dashboard/listing-user-comments";
import ListingUsers from "../../layouts/dashboard/listing-users";
import DeleteAccount from "../../layouts/dashboard/delete-account";
import Logout from "../../layouts/auth/logout";
import userStatus from "../userStatus";

function MainSidenav(props) {
  const { activeSidenaveItem } = props;
  const { userData } = userStatus(true);

  const [selectedId, setSelectedId] = useState(null);

  // edit-disappeared
  const handleEditDisappeared = (value) => {
    props.activeSidenav(7);
    setSelectedId(value);
  };

  // edit-comment
  const handleEditComment = (value) => {
    props.activeSidenav(8);
    setSelectedId(value);
  };

  // handle-is-back
  const handleIsBack = (value) => {
    props.activeSidenav(value);
  };

  return (
    <Box
      component="main"
      sx={{
        overflowX: "auto",
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${240}px)` },
        overflowY: "scroll",
        height: "100vh",
      }}
    >
      <Toolbar />
      {activeSidenaveItem === 1 && (
        <ListingDisappearedWithComments
          editId={handleEditDisappeared}
          editCommentId={handleEditComment}
        />
      )}
      {activeSidenaveItem === 2 && <NewDisappeared isBack={handleIsBack} />}
      {activeSidenaveItem === 3 && (
        <ListingUserComments editCommentId={handleEditComment} />
      )}
      {activeSidenaveItem === 5 && userData?.user_type === "admin" && (
        <ListingUsers />
      )}
      {activeSidenaveItem === 7 && (
        <EditDisappeared isBack={handleIsBack} id={selectedId} />
      )}
      {activeSidenaveItem === 8 && (
        <EditComment back={1} isBack={handleIsBack} id={selectedId} />
      )}
      {activeSidenaveItem === 9 && <Logout />}
      {activeSidenaveItem === 10 && <DeleteAccount isBack={handleIsBack} />}
    </Box>
  );
}

export default MainSidenav;
