/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */

// @react
import { useState, useEffect } from "react";

// @css
import "./index.css";
// @Formik
import { Form, Formik } from "formik";
// @Formik-Components
import ResetPasswordForm from "./components/reset-password-form";
import form from "./components/schemas/form";
import validations from "./components/schemas/validations";
import initialValues from "./components/schemas/initiale-values";
// @mui
import { Card, Box, Button } from "@mui/material";
// @snackbar
import Snackbar from "../../../components/Snackbar";
// @api
import { User, Email } from "../../../utils/fetcher-api";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// get-current-dateTime
import { getCurrentDateTime } from "../../../components/get-current-date-time";
// @components
import EncryptData from "../../../components/encrypt-data";
import EmailSuccessSentMsg from "../../../components/email-success-sent-msg";
function resetPassword() {
  // use-@react-router-dom
  const navigate = useNavigate();

  // use @Formik For New User
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [isSentResetLink, setIsSentResetLink] = useState(false);

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // handle-response-&-errors
  async function handleResponseErrorsGenerationResetPassword(response) {
    if (response.status) {
      if (response.code === 200) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("! تم إنشاء رابط إعادة تعيين كلمة المرور بنجاح ");

        await sleep(1000);

        return true;
      }
    } else {
      if (response.code === 404) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! البريد الإلكتروني غير صحيح");
      } else if (response.code === 422) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! المعطيات غير صالحة");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage(".خطأ، حاول مرة أخرى");
      }
      setIsSubmitting(false);
      return false;
    }
  }
  async function handleResponseErrorsSentEmailRestPassword(response) {
    if (response.status) {
      if (response.code === 200) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("تم إرسال البريد الإلكتروني بنجاح !");

        await sleep(1000);
        setIsSentResetLink(true);
        setIsSubmitting(false);
        await sleep(7000);
        // navigate("/auth/sign-in");
      }
    } else {
      if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage(".222خطأ، حاول مرة أخرى");
      }
      setIsSentResetLink(false);
      setIsSubmitting(false);
    }
  }

  //sent-email-reset-password
  const sentEmailRestPassword = async (resp, generateResetPassword) => {
    try {
      const form = {
        recipient_email: resp?.data?.email,
        subject: "رابط إعادة تعيين كلمة المرور",
        recipient_name: resp?.data?.name,
        reset_link: generateResetPassword.replace(/\//g, "--"),
      };
      const response = await Email.resetPasswordLink(form);
      await handleResponseErrorsSentEmailRestPassword(response);
    } catch (error) {
      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage(".خطأ، حاول مرة أخرى");
    }
  };

  // submit-ResetPassword
  const submitGenerationResetPassword = async (values, actions) => {
    actions.setSubmitting(true);
    setIsSubmitting(true);
    await sleep(1000);

    const { email } = values;
    const currentDateTime = await getCurrentDateTime();
    // const currentDateTime = "2023-07-22 13:20:00";
    const generateResetPassword = await EncryptData(
      email + "*" + currentDateTime
    );

    const resetPassword = {
      email: email,
      updated_at: currentDateTime,
      generate_reset_password: generateResetPassword,
    };

    try {
      const response = await User.generationResetPassword(resetPassword);
      await sleep(1000);

      // handle-response-&-errors
      if (await handleResponseErrorsGenerationResetPassword(response))
        sentEmailRestPassword(response, generateResetPassword);

      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage(".خطأ، حاول مرة أخرى");
    }
  };

  const handleSubmit = (values, actions) => {
    setIsSubmitting(true);
    submitGenerationResetPassword(values, actions);
    // actions.setTouched({});
    actions.setSubmitting(false);
    setIsSubmitting(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    document.title = "مختفون | استرجاع كلمة السر";
  });
  return (
    <>
      <Box className="box">
        {isSentResetLink ? (
          <EmailSuccessSentMsg />
        ) : (
          <section className="section">
            <h1>استرجاع كلمة السر</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                resetForm,
              }) => (
                <Form
                  dir="rtl"
                  id={formId}
                  autoComplete="off"
                  style={{
                    pointerEvents: IsSubmitting ? "none" : "painted",
                    opacity: IsSubmitting ? 0.4 : 1,
                  }}
                >
                  <Card
                    sx={{
                      height: "100%",
                      background: "transparent",
                    }}
                  >
                    <Box
                      color="white"
                      background="info"
                      variant="gradient"
                      borderRadius="lg"
                      shadow="lg"
                      opacity={1}
                      sx={{ textTransform: "uppercase" }}
                    >
                      <Box p={2}>
                        <Box sx={{ pt: 3 }}>
                          <ResetPasswordForm
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                              handleChange,
                              handleBlur,
                            }}
                          />
                        </Box>

                        <Box
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Button
                            className="button"
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            color="info"
                          >
                            {IsSubmitting ? "..." : "استرجع كلمة السر"}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Form>
              )}
            </Formik>

            <div className="register">
              <p>
                ليس لديك حساب{" "}
                <a
                  onClick={(e) => [
                    e.preventDefault(),
                    navigate("/auth/sign-up"),
                  ]}
                  href="#"
                >
                  تسجل الان
                </a>
              </p>
            </div>
          </section>
        )}
      </Box>
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
    </>
  );
}

export default resetPassword;
