/* eslint-disable jsx-a11y/anchor-is-valid */

// @Css-File
import "./index.css";
// @MUI-Components
import { Box, Button } from "@mui/material";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @Img
import cover from "../../img/cover.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";

function Cover() {
  // @USE--react-router-dom
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
        height: {
          xs: "400px",
          sm: "400px",
          md: "fit-content",
          lg: "fit-content",
        },
        pt: { xs: "129px", sm: "220px", md: "20px", lg: "20px" },
        pb: { xs: "129px", sm: "200px", md: "20px", lg: "20px" },
      }}
      className="cover"
    >
      <div className="col_cover">
        <Button onClick={() => navigate("/auth/sign-in")}>
          <img src={cover} alt="cover_img" className="cover_img" />
        </Button>

        <Button onClick={() => navigate("/auth/sign-in")}>
          <AddCircleIcon className="add_people_cover" />
        </Button>
      </div>
      <div className="col_cover">
        <span className="cover_txt_principe">ساعد في لم الشمل من جديد</span>
        <span className="cover_txt_secondary">
          لنعد الفرحة لقلوب تضررت من الفراق
        </span>
      </div>
    </Box>
  );
}

export default Cover;
