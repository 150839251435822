/* eslint-disable array-callback-return */
// @Mui
import { Skeleton, Box } from "@mui/material";

function DashCommentsSkeleton() {
  const arr = [1, 2, 3, 4];
  return (
    <Box className="cards" sx={{ alignItems: "center", overflow: "hidden" }}>
      {arr.map((item) => {
        return (
          <Box
            key={item}
            sx={{
              pt: 2,
              pb: 2,
              width: "90%",
            }}
          >
            <Skeleton height={15} width="30%" />
            <Skeleton height={10} />
            <Skeleton height={10} />
            <Skeleton height={10} />
            <Skeleton height={10} />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Skeleton height={15} width="30%" />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default DashCommentsSkeleton;
