// @yup
import * as Yup from "yup";
// @form
import checkout from "./form";
// @reg-exp
import { PasswordRegExp } from "../../../../../utils/RegExp";

const {
  formField: { password, confirmationPassword },
} = checkout;

const validations = [
  Yup.object().shape({
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(8, password.minValue)
      .matches(PasswordRegExp, { message: password.invalidMsg }),
    [confirmationPassword.name]: Yup.string()
      .min(8, confirmationPassword.minValue)
      .oneOf([Yup.ref("password"), null], confirmationPassword.invalidMsg)
      .required(confirmationPassword.errorMsg),
  }),
];

export default validations;
