/* eslint-disable camelcase */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
/* eslint-disable object-shorthand */
/* eslint-disable no-console */

// @react
import { useState, useEffect } from "react";
// @Formik
import { Form, Formik } from "formik";
// @mui
import { Grid, Card, Box, Button, Typography } from "@mui/material";
// @Formik-components
import LoginForm from "./components/NewDisappearedForm";
import form from "./components/Shemas/form";
import validations from "./components/Shemas/validations";
import initialValues from "./components/Shemas/initialValues";
// @Options
import { Genders, typesDisappeared } from "../../../utils/select-options";
import cities from "../../../utils/files-json/Cities-Maroc.json";
import directions from "../../../utils/files-json/Directions-Maroc.json";
import provinces from "../../../utils/files-json/Provinces-Maroc.json";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @components
import Snackbar from "../../../components/Snackbar";
import userStatus from "../../../components/userStatus";
import { getCurrentDateTime } from "../../../components/get-current-date-time";
import UploadImage from "../../../components/upload-image";
// @API
import { Disappeared } from "../../../utils/fetcher-api";

function NewDisappeared(props) {
  const { userData } = userStatus(true);
  // use-@react-router-dom
  const navigate = useNavigate();

  const handleIsBack = () => {
    props.isBack(1);
  };

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // use @Formik For New User
  const { formId, formField } = form;
  const currentValidation = validations[0];
  const [IsSubmitting, setIsSubmitting] = useState(false);

  // handle-response-&-errors
  async function handleResponseErrors(response) {
    if (response.status) {
      if (response.code === 201) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("! تم اضافة مختفي بنجاح");

        return true;
      }
    } else {
      if (response.code === 401) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! قم بتسجيل الدخول اولا");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 422) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! المعطيات غير صالحة");
      }
    }
    return false;
  }

  // upload-img
  const handleUploadImg = async (file, userId, dateTime) => {
    const path = await UploadImage(file, userId, dateTime);
    return path;
  };

  const submitNewDisappeared = async (values, actions) => {
    actions.setSubmitting(true);
    setIsSubmitting(true);
    await sleep(1000);

    try {
      const currentDateTime = await getCurrentDateTime();

      const {
        firstname,
        lastname,
        age,
        datedisappeared,
        gender,
        direction,
        province,
        otherProvince,
        city,
        otherCity,
        typedisappeared,
        phonenumber,
        imagedisappeared,
      } = values;

      const path = await handleUploadImg(
        imagedisappeared,
        userData.id,
        currentDateTime
      );
      if (path !== "") {
        const disappearedToAdd = {
          user_id: userData.id,
          type: typedisappeared,
          first_name: firstname === "" ? null : firstname,
          last_name: lastname === "" ? null : lastname,
          age: age === "" ? null : age,
          direction: direction,
          province: province === "آخرى" ? otherProvince : province,
          city: city === "آخرى" ? otherCity : city,
          date_losted: datedisappeared === "" ? null : datedisappeared,
          gender: gender,
          phone: phonenumber === "" ? null : phonenumber,
          picture: path,
          created_at: currentDateTime,
          updated_at: currentDateTime,
        };
        const response = await Disappeared.create(disappearedToAdd);
        if (response) {
          if (await handleResponseErrors(response)) {
            actions.resetForm();
            handleIsBack();
          }
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("! لم يتم تحميل الصورة..حاول مرة اخرى");
      }
    } catch (error) {
      actions.setSubmitting(false);
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("! حدث خطا..حاول مرة اخرى");
    }
  };

  const handleSubmit = (values, actions) => {
    setIsSubmitting(true);
    submitNewDisappeared(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
    setIsSubmitting(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    document.title = "مختفون | اضافة مختفي";
  });

  return (
    <>
      <Box dir="rtl" sx={{ mt: { xs: 10, sm: 10, md: 0 } }}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                resetForm,
              }) => (
                <Form
                  id={formId}
                  autoComplete="off"
                  style={{
                    pointerEvents: IsSubmitting ? "none" : "painted",
                    opacity: IsSubmitting ? 0.4 : 1,
                  }}
                >
                  <Card
                    elevation={3}
                    sx={{ height: "100%", m: { xs: 2, sm: 2, md: 10, lg: 10 } }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textTransform: "uppercase",
                        color: "#fff",
                        bgcolor: "#1ca4c7",
                        p: 2,
                      }}
                    >
                      <Typography variant="h4">اضافة مختفي</Typography>
                    </Box>
                    <Box p={2}>
                      <Box sx={{ pt: 3 }}>
                        <LoginForm
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                            handleChange,
                            handleBlur,
                            Genders,
                            typesDisappeared,
                            directions,
                            provinces,
                            cities,
                          }}
                        />
                      </Box>
                      <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-evenly"
                      >
                        <Button
                          onClick={() => resetForm()}
                          disabled={isSubmitting}
                          variant="outlined"
                          color="info"
                        >
                          مسح الكل
                        </Button>
                        <Button
                          sx={{ bgcolor: "#1ca4c7" }}
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          {IsSubmitting ? "اضف ..." : "اضف مختفي"}
                        </Button>
                      </Box>
                    </Box>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
    </>
  );
}

export default NewDisappeared;
