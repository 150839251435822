/* eslint-disable jsx-a11y/anchor-is-valid */

// @Css-File
import "./index.css";
// @jquery
import $ from "jquery";
// @react
import { useEffect } from "react";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @Img
// import logo from "../../img/logo.svg";
import logo from "../../img/logo.png";

//import cercle from "../img/cercle.svg";
// @MUI-Components
import { IconButton, Typography, Button } from "@mui/material";
// @MUI-Icons
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// @Pages
import SubHeader from "../../components/sub-header";
import Loading from "../../components/loading";
import MenuMobile from "../../components/menu-mobile";

function Header() {
  // @USE--react-router-dom
  const navigate = useNavigate();
  $(window).on("scroll", function () {
    if (window.scrollY === 0) {
      $(".header").css({ position: "absolute" });
    } else {
      $(".header").css({ position: "fixed" });
    }
  });

  useEffect(() => {
    $(".over_loading").css({ display: "flex" });
    setTimeout(() => {
      $(".over_loading").css({ display: "none" });
    }, 3000);
  });

  return (
    <>
      <section className="header">
        <a
          style={{ paddingTop: "10px" }}
          href="#"
          onClick={(e) => [e.preventDefault(), navigate("/")]}
        >
          <img src={logo} alt="findthem-logo" className="header_logo" />
          <Typography
            variant="subtitle"
            sx={{
              color: "#16a4c7",
              opacity: 0.5,
              fontSize: "smaller",
              fontFamily: "monospace",
            }}
          >
            Beta
          </Typography>
        </a>

        <Button
          onClick={(e) => navigate("/dashboard")}
          size="small"
          startIcon={
            <AddCircleIcon fontSize="20px" className="header_btn_icon" />
          }
          sx={{
            border: "2px solid #1CA4C7",
            background: "#1CA4C7",
            color: "#BEF2FF",
            ":hover": {
              color: "#1CA4C7",
            },
          }}
        >
          <span className="header_btn_txtPrincip">إضافة مختفي </span>
        </Button>
      </section>

      {/* <MenuMobile /> */}
      {/* <SubHeader /> */}
      <Loading />
    </>
  );
}
export default Header;
