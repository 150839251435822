/* eslint-disable react-hooks/exhaustive-deps */
// @react
import { useEffect, useState } from "react";
// @components
import CategoryPeople from "../../components/category-people";
// @API
import { Disappeared } from "../../utils/fetcher-api";

function SomePeople() {
  const [disappeareds, setDisappeareds] = useState([]);
  const [tramps, setTramps] = useState([]);

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const getDisappeareds = async () => {
    try {
      const filtration = {
        type_disappeared: "2",
        is_found: "not",
        itemPerPage: 3,
        currentPage: 1,
        isFiltration: true,
      };

      const response = await Disappeared.list(filtration);
      await sleep(1000);
      if (response.status && response.code === 200) {
        setDisappeareds(Object.values(response.data.data));
      } else if (response.code !== 200) {
        setDisappeareds([]);
      }
    } catch (error) {
      setDisappeareds([]);
    }
  };

  const getTramps = async () => {
    const filtration = {
      type_disappeared: "1",
      is_found: "not",
      itemPerPage: 3,
      currentPage: 1,
      isFiltration: true,
    };

    try {
      const response = await Disappeared.list(filtration);
      await sleep(1000);
      if (response.status && response.code === 200) {
        setTramps(Object.values(response.data.data));
      } else if (response.code !== 200) {
        setTramps([]);
      }
    } catch (error) {
      setDisappeareds([]);
    }
  };

  useEffect(() => {
    getDisappeareds();
    getTramps();
  }, []);

  useEffect(() => {
    document.title = "مختفون | الصفحة الرئيسية";
  });

  return (
    <>
      <CategoryPeople
        title="صور المختفيين"
        subTitle="هنا ستجد بعض صور المختفين الذين ثم اضافتهم من طرف أسرهم"
        redirect="/disappeared"
        type="disappeared"
        data={disappeareds}
      />
      <CategoryPeople
        title="صور المتشردين"
        subTitle="هنا ستجد بعض صور المتشردين الذين إلتقاطة صورهم في الشارع"
        redirect="/disappeared"
        type="tramp"
        data={tramps}
      />
    </>
  );
}

export default SomePeople;
