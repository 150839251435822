// @react
import React from "react";
// @Mui
import { Box, Typography } from "@mui/material";
function NoItems({ img, msg }) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={img}
        alt={msg}
        srcSet={img}
        style={{ height: "200px", width: "200px" }}
      />
      <Typography sx={{ mt: 3 }} variant="h4" gutterBottom>
        {msg}
      </Typography>
    </Box>
  );
}

export default NoItems;
