/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
// @Css-File
import "./index.css";
// @react
import { useEffect, useState } from "react";
// @Mui-components
import {
  Grid,
  Container,
  Box,
  Pagination,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
// @components
import Filtration from "./components/filtration";
import CardDisappeared from "../../../components/card-disappeared";
import CardTramp from "../../../components/card-tramp";
import ItemsSkeleton from "../../../components/skeletons/items-skeleton";
import CommentPopup from "../../../components/comment-popup";
import NoItems from "../../../components/no-items";
// @API
import { Disappeared } from "../../../utils/fetcher-api";
// @img
import search from "../../../img/search.png";

function ListingDisappeared() {
  const [filtrationObject, setFiltrationObject] = useState({});
  const [itemPerPage, setItemPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [NbrPages, setNbrPages] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // list-disappeared-filtred
  const [disappearedFiltred, setDisappearedFiltred] = useState("");
  // comment-Id
  const [disappearedId, setDisappearedId] = useState(null);
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const handleCurrentPageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemPerPageChange = (value) => {
    setItemPerPage(value);
    setCurrentPage(1);
  };

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // filtration-change
  const handleFiltrationChange = async (value) => {
    const isAtLeastOneFilled = await Object.values(value).some(
      (value) => value !== ""
    );
    if (!isAtLeastOneFilled) {
      handleItemPerPageChange(5);
    }
    setFiltrationObject(value);
  };

  // get-filtred-disappeared
  const getDisappearedFiltred = async () => {
    setIsSubmitting(true);

    const filtration = {
      first_name: filtrationObject.first_name,
      last_name: filtrationObject.last_name,
      type_disappeared: filtrationObject.type_disappeared,
      gender: filtrationObject.gender,
      direction: filtrationObject.direction,
      province: filtrationObject.province,
      city: filtrationObject.city,
      date_lost: filtrationObject.date_disappeared,
      is_found: filtrationObject.is_found,
      itemPerPage: itemPerPage,
      currentPage: currentPage,
      isFiltration: true,
    };

    const response = await Disappeared.list(filtration);

    if (response.status && response.code === 200) {
      setNbrPages(response.data.last_page);
      setDisappearedFiltred(Object.values(response.data.data));
    } else if (response.code !== 200) {
      setDisappearedFiltred("");
    }

    await sleep(1000);

    setIsSubmitting(false);
  };

  const handleComment = (value) => {
    setDisappearedId(value.disappearedId);
    setIsCommentOpen(value.status);
  };

  // comment-status
  const handleCommentClosed = (value) => {
    setDisappearedId(value.disappearedId);
    setIsCommentOpen(value.status);
  };

  const commentStatus = {
    disappearedId: disappearedId,
    status: isCommentOpen,
  };

  // pagination
  useEffect(() => {
    getDisappearedFiltred();
  }, [itemPerPage, currentPage, filtrationObject]);

  useEffect(() => {
    document.title = "مختفون | قائمة المختفين";
  });

  return (
    <>
      <Container fixed sx={{ mt: 7 }}>
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Filtration onChange={handleFiltrationChange} />
          <Grid className="dashboard_details" item xs={12} md={12} lg={9}>
            <Box
              className="cards scroll_filtration"
              sx={{
                mt: { xs: "60px" },
                height: { xs: "90vh", sm: "70vh", md: "80vh", lg: "80vh" },
                overflowX: { xs: "hidden" },
              }}
            >
              {isSubmitting ? (
                <ItemsSkeleton />
              ) : disappearedFiltred.length > 0 ? (
                disappearedFiltred.map((item) => {
                  return (
                    <>
                      {item.type === "1" ? (
                        <CardTramp
                          key={item.id}
                          commentStatus={handleComment}
                          data={item}
                        />
                      ) : (
                        <CardDisappeared
                          key={item.id}
                          commentStatus={handleComment}
                          data={item}
                        />
                      )}
                    </>
                  );
                })
              ) : (
                <NoItems img={search} msg="! لا يوجد اي مختفي حاليا" />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "space-between",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                },
                mt: 2,
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">
                  العناصر في كل صفحة
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={itemPerPage}
                  label="العناصر في كل صفحة"
                  onChange={(e) => handleItemPerPageChange(e.target.value)}
                >
                  <MenuItem value="5"></MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                </Select>
              </FormControl>
              <Stack
                spacing={2}
                sx={{
                  pb: { xs: 2, sm: 2, md: 2, lg: 0 },
                  pt: { xs: 2, sm: 2, md: 0 },
                  alignItems: { xs: "center", sm: "center" },
                }}
              >
                <Pagination
                  page={currentPage}
                  onChange={handleCurrentPageChange}
                  count={NbrPages}
                  variant="outlined"
                  size="small"
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <CommentPopup
          commentStatus={commentStatus}
          commentClosed={handleCommentClosed}
        />
      </Container>
    </>
  );
}

export default ListingDisappeared;
