const form = {
  formId: "filtration-form",
  formField: {
    first_name: {
      name: "first_name",
      label: "الاسم الشخصي",
      type: "text",
      errorMsg: "الاسم الشخصي مطلوب.",
      minValue: "",
      invalidMsg: "الاسم الشخصي غير صالح.",
      placeholder: "الرجاء إدخال الاسم الشخصي",
    },
    last_name: {
      name: "last_name",
      label: "الاسم العائلي",
      type: "text",
      errorMsg: "الاسم العائلي مطلوب.",
      minValue: "ثلاثة احرف على الاقل.",
      invalidMsg: "الاسم العائلي غير صالح.",
      placeholder: "الرجاء إدخال الاسم العائلي",
    },
    age: {
      name: "age",
      label: "السن",
      type: "number",
      errorMsg: ".السن مطلوب",
      minValue: ".رقم على الأقل",
      invalidMsg: ".السن غير صالح",
      placeholder: ".الرجاء إدخال السن",
    },
    gender: {
      name: "gender",
      label: "الجنس",
      errorMsg: "الجنس مطلوب.",
      minValue: "",
      invalidMsg: "الجنس غير صالح.",
      placeholder: "الرجاء اختيار الجنس",
    },
    direction: {
      name: "direction",
      label: "الجهة",
      errorMsg: "الجهة مطلوب.",
      minValue: "",
      invalidMsg: "الجهة غير صالح.",
      placeholder: "الرجاء اختيار الجهة",
    },
    province: {
      name: "province",
      label: "العمالة او الاقليم",
      errorMsg: "العمالة او الاقليم مطلوب.",
      minValue: "",
      invalidMsg: "العمالة او الاقليم غير صالح.",
      placeholder: "الرجاء اختيار العمالة او الاقليم",
    },
    city: {
      name: "city",
      label: "المدينة أو القرية",
      errorMsg: "المدينة أو القرية مطلوبة.",
      minValue: "",
      invalidMsg: "المدينة أو القرية غير صالحة.",
      placeholder: "الرجاء اختيار المدينة أو القرية",
    },
    date_disappeared: {
      name: "date_disappeared",
      label: "تاريخ الاختفاء",
      type: "date",
      errorMsg: "تاريخ الاختفاء مطلوب.",
      minValue: "",
      invalidMsg: "تاريخ الاختفاء غير صالح.",
      placeholder: "الرجاء اختيار تاريخ الاختفاء",
    },
    type_disappeared: {
      name: "type_disappeared",
      label: "صنف المختفي",
      errorMsg: ".صنف المختفي مطلوب ",
      minValue: "",
      invalidMsg: ".صنف المختفي غير صالح",
      placeholder: "الرجاء إدخال صنف المختفي",
    },
    is_found: {
      name: "is_found",
      label: "هل وجد(ت) المختفي(ة) ؟",
      errorMsg: ".صنف المختفي مطلوب ",
      minValue: "",
      invalidMsg: "",
      placeholder: "",
    },
  },
};
export default form;
