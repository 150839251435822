//@Mui
import Grid from "@mui/material/Grid";
//@Fields
import FormField from "../form-field";

function LoginForm({ formData }) {
  const { formField, values, errors, touched } = formData;
  let { email, password } = formField;
  let { email: emailValue, password: passwordValue } = values;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormField
          type={email.type}
          label={email.label}
          name={email.name}
          value={emailValue}
          placeholder={email.placeholder}
          error={errors.email && touched.email}
          success={(emailValue.length > 0 && !errors.email).toString()}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormField
          type={password.type}
          label={password.label}
          name={password.name}
          value={passwordValue}
          placeholder={password.placeholder}
          error={errors.password && touched.password}
          success={(passwordValue.length > 0 && !errors.password).toString()}
        />
      </Grid>
    </Grid>
  );
}

export default LoginForm;
