import { Box, IconButton, Typography } from "@mui/material";
// Mui-Icons
import RoomIcon from "@mui/icons-material/Room";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// @components
import TimePassed from "../../time-passed";

function CommentRecordTemp(props) {
  const { data, inDash } = props;

  // edit-comment
  const handleEditComment = (value) => {
    props.editCommentId(value);
  };

  // delete-comment
  const handleDeleteComment = (value) => {
    props.deleteCommentId(value);
  };

  return (
    <Box
      key={data?.id}
      sx={{
        m: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <RoomIcon sx={{ color: "#1CA4C7" }} />
        <Typography
          level="body2"
          sx={{
            textAlign: "right",
            color: "#1CA4C7",
            wordWrap: "break-word",
          }}
        >
          {data?.direction}، {data?.province}، {data?.city}
        </Typography>
      </Box>
      <Box sx={{ mt: 1, textAlign: "right" }}>
        <Typography sx={{ wordWrap: "break-word" }} variant="body1">
          {data?.comment}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          justifyContent: inDash ? "space-between" : "left",
          color: "#1CA4C7",
          mt: 1,
        }}
      >
        {inDash && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              mb: 2,
              mt: 1,
            }}
          >
            <IconButton
              sx={{ color: "green" }}
              aria-label="expand row"
              size="small"
              onClick={() => handleEditComment(data?.id)}
            >
              <AutoFixHighIcon fontSize="small" />
            </IconButton>
            <IconButton
              sx={{ color: "red" }}
              aria-label="expand row"
              size="small"
              onClick={() => handleDeleteComment(data?.id)}
            >
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
        <Typography variant="body1">
          <TimePassed datetime={data?.created_at} />
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 1 }}>
        <Typography
          sx={{
            textAlign: "center",
            width: "50%",
            borderBottom: "1px solid #1ca4c754",
          }}
        />
      </Box>
    </Box>
  );
}

export default CommentRecordTemp;
