/* eslint-disable no-unused-vars */

//@Yup
import * as Yup from "yup";
//@Form
import checkout from "./form";

const {
  formField: { comment, direction, province, otherProvince, city, otherCity },
} = checkout;

const validations = [
  Yup.object().shape({
    [comment.name]: Yup.string()
      .required(comment.errorMsg)
      .min(50, comment.minValue),

    [direction.name]: Yup.string().required(direction.errorMsg),

    [province.name]: Yup.string().required(province.errorMsg),
    [otherProvince.name]: Yup.string().when("province", {
      is: (province) => province === "آخرى",
      then: (schema) => schema.required(otherProvince.errorMsg),
      otherwise: (schema) => schema.notRequired(),
    }),

    [city.name]: Yup.string().required(city.errorMsg),
    [otherCity.name]: Yup.string().when("city", {
      is: (province) => province === "آخرى",
      then: (schema) => schema.required(otherCity.errorMsg),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
];

export default validations;
