//@Mui
import Grid from "@mui/material/Grid";
//@Fields
import FormField from "../FormField";

function NewDisappearedForm({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    Genders,
    directions,
    provinces,
    cities,
    typesDisappeared,
  } = formData;

  let {
    firstname,
    lastname,
    age,
    datedisappeared,
    gender,
    direction,
    province,
    otherProvince,
    city,
    otherCity,
    typedisappeared,
    phonenumber,
    imagedisappeared,
  } = formField;

  let {
    firstname: firstnameValue,
    lastname: lastnameValue,
    age: ageValue,
    datedisappeared: datedisappearedValue,
    gender: genderValue,
    direction: directionValue,
    province: provinceValue,
    otherProvince: otherProvinceValue,
    city: cityValue,
    otherCity: otherCityValue,
    typedisappeared: typedisappearedValue,
    phonenumber: phonenumberValue,
    imagedisappeared: imagedisappearedValue,
  } = values;

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          pb: 7,
          p: 3,
        }}
      >
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={typesDisappeared}
            isGroupRadio
            type={typedisappeared.type}
            label={typedisappeared.label}
            name={typedisappeared.name}
            value={typedisappearedValue}
            error={errors.typedisappeared && touched.typedisappeared}
            success={(
              typedisappearedValue.length > 0 && !errors.typedisappeared
            ).toString()}
            placeholder={typedisappeared.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={lastname.type}
            label={lastname.label}
            name={lastname.name}
            value={lastnameValue}
            placeholder={lastname.placeholder}
            error={errors.lastname && touched.lastname}
            success={(lastnameValue.length > 0 && !errors.lastname).toString()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={firstname.type}
            label={firstname.label}
            name={firstname.name}
            value={firstnameValue}
            placeholder={firstname.placeholder}
            error={errors.firstname && touched.firstname}
            success={(
              firstnameValue.length > 0 && !errors.firstname
            ).toString()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={age.type}
            label={age.label}
            name={age.name}
            value={ageValue}
            placeholder={age.placeholder}
            error={errors.age && touched.age}
            success={(ageValue.length > 0 && !errors.age).toString()}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormField
            type={phonenumber.type}
            label={phonenumber.label}
            name={phonenumber.name}
            value={phonenumberValue}
            placeholder={phonenumber.placeholder}
            error={errors.phonenumber && touched.phonenumber}
            success={(
              phonenumberValue.length > 0 && !errors.phonenumber
            ).toString()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={datedisappeared.type}
            label={datedisappeared.label}
            name={datedisappeared.name}
            value={datedisappearedValue}
            placeholder={datedisappeared.placeholder}
            error={errors.datedisappeared && touched.datedisappeared}
            success={(
              datedisappearedValue.length > 0 && !errors.datedisappeared
            ).toString()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={Genders}
            isGroupRadio
            type={gender.type}
            label={gender.label}
            name={gender.name}
            value={genderValue}
            error={errors.gender && touched.gender}
            success={(genderValue.length > 0 && !errors.gender).toString()}
            placeholder={gender.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={directions}
            isAutoComplete
            type={direction.type}
            label={direction.label}
            name={direction.name}
            value={directionValue}
            error={(errors.direction && touched.direction)?.toString()}
            success={(
              directionValue?.length > 0 && !errors.direction
            ).toString()}
            placeholder={direction.placeholder}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={provinces.filter(
              (p) => p.direction === directionValue || p.direction === "آخرى"
            )}
            isAutoComplete
            type={province.type}
            label={province.label}
            name={province.name}
            value={provinceValue}
            error={(errors.province && touched.province)?.toString()}
            success={(provinceValue?.length > 0 && !errors.province).toString()}
            placeholder={province.placeholder}
          />
        </Grid>
        {provinceValue === "آخرى" && (
          <Grid item xs={12} sm={6}>
            <FormField
              type={otherProvince.type}
              label={otherProvince.label}
              name={otherProvince.name}
              value={otherProvinceValue}
              placeholder={otherProvince.placeholder}
              error={errors.otherProvince && touched.otherProvince}
              success={(
                otherProvinceValue.length > 0 && !errors.otherProvince
              ).toString()}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <FormField
            selectOptions={cities.filter((c) => c.province === provinceValue)}
            isAutoComplete
            type={city.type}
            label={city.label}
            name={city.name}
            value={cityValue}
            error={(errors.city && touched.city)?.toString()}
            success={(cityValue?.length > 0 && !errors.city).toString()}
            placeholder={city.placeholder}
          />
        </Grid>
        {cityValue === "آخرى" && (
          <Grid item xs={12} sm={6}>
            <FormField
              type={otherCity.type}
              label={otherCity.label}
              name={otherCity.name}
              value={otherCityValue}
              placeholder={otherCity.placeholder}
              error={errors.otherCity && touched.otherCity}
              success={(
                otherCityValue.length > 0 && !errors.otherCity
              ).toString()}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          flexDirection="column"
          display="flex"
        >
          <FormField
            type={imagedisappeared.type}
            label={imagedisappeared.label}
            name={imagedisappeared.name}
            isImg
            value={imagedisappearedValue}
            placeholder={imagedisappeared.placeholder}
            error={errors.imagedisappeared && touched.imagedisappeared}
            success={(
              imagedisappearedValue?.length > 0 && !errors.imagedisappeared
            ).toString()}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default NewDisappearedForm;
