/* eslint-disable react-hooks/exhaustive-deps */

// @react
import { useState, useEffect } from "react";
// @API
import { User } from "../../utils/fetcher-api";
// @components
import Snackbar from "../../components/Snackbar";
import Submitting from "../../components/submitting";
import userStatus from "../../components/userStatus";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @js-cookie
import Cookies from "js-cookie";
import AlertDialog from "../../components/alert-dialog";

function DeleteAccount(props) {
  // use-@react-router-dom
  const navigate = useNavigate();

  const { userData } = userStatus(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleResponseErrorsForDeleteUser(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("تم حذف الحساب بنجاح !");
        await sleep(1000);
        Cookies.remove("token");
        navigate("/disappeared");
        return true;
      }
    } else {
      if (response.code === 403) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      }
    }
    return false;
  }
  // alert-dialog
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const handleDeleteConfirmationChange = async (confirmed) => {
    if (confirmed) {
      deleteUserAccount();
    } else {
      handleIsBack();
    }
    setIsOpenDialog(false);
  };

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };
  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const deleteUserAccount = async () => {
    setIsSubmitting(true);

    try {
      const response = await User.delete(userData.id);
      await sleep(1000);
      await handleResponseErrorsForDeleteUser(response);
    } catch (error) {
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("خطا..حاول مرة اخرى !");
    }
  };

  useEffect(() => {
    if (userData?.id !== null) setIsOpenDialog(true);
  }, [userData]);

  useEffect(() => {
    document.title = "مختفون | حذف الحساب";
  });

  // handle-is-back
  const handleIsBack = () => {
    props.isBack(1);
  };

  return (
    <>
      {isSubmitting && <Submitting msg="حذف الحساب" />}
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />

      <AlertDialog
        name
        confirmation={handleDeleteConfirmationChange}
        isOpen={isOpenDialog}
      />
    </>
  );
}

export default DeleteAccount;
