const form = {
  formId: "new-comment",
  formField: {
    comment: {
      name: "comment",
      label: "التعليق",
      type: "text",
      errorMsg: ".التعليق مطلوب",
      minValue: ".على الاقل 50 حروفا",
      invalidMsg: ".التعليق غير صالح",
      placeholder: "الرجاء إدخال التعليق",
    },
    direction: {
      name: "direction",
      label: "جهة الإختفاء",
      errorMsg: ".جهة الإختفاء مطلوبة",
      minValue: "",
      invalidMsg: ".جهة الإختفاء غير صالحة",
      placeholder: " الرجاء إدخال جهة الإختفاء",
    },
    province: {
      name: "province",
      label: "عمالة او اقليم الإختفاء",
      errorMsg: ".عمالة او اقليم الإختفاء مطلوب ",
      minValue: "",
      invalidMsg: ".عمالة او اقليم الإختفاء غير صالح",
      placeholder: "الرجاء إدخال عمالة او اقليم الإختفاء",
    },
    otherProvince: {
      name: "otherProvince",
      label: "عمالة او اقليم الإختفاء",
      type: "text",
      errorMsg: ".عمالة او اقليم الإختفاء مطلوب ",
      minValue: "",
      invalidMsg: ".عمالة او اقليم الإختفاء غير صالح",
      placeholder: "الرجاء إدخال عمالة او اقليم الإختفاء",
    },
    city: {
      name: "city",
      label: "مدينة او قرية الإختفاء",
      errorMsg: ".مدينة او قرية الإختفاء مطلوبة",
      minValue: "",
      invalidMsg: ".مدينة او قرية الإختفاء غير صالحة",
      placeholder: "الرجاء إدخال مدينة او قرية الإختفاء",
    },
    otherCity: {
      name: "otherCity",
      label: "مدينة او قرية الإختفاء",
      type: "text",
      errorMsg: ".مدينة او قرية الإختفاء مطلوبة",
      minValue: "",
      invalidMsg: ".مدينة او قرية الإختفاء غير صالحة",
      placeholder: "الرجاء إدخال مدينة او قرية الإختفاء",
    },
  },
};
export default form;
