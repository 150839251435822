//@Form
import checkout from "./form";

const {
  formField: {
    firstname,
    lastname,
    age,
    datedisappeared,
    gender,
    direction,
    province,
    otherProvince,
    city,
    otherCity,
    typedisappeared,
    phonenumber,
    imagedisappeared,
  },
} = checkout;

const initialValues = {
  [firstname.name]: "",
  [lastname.name]: "",
  [age.name]: "",
  [datedisappeared.name]: "",
  [gender.name]: "1",
  [direction.name]: "",
  [province.name]: "",
  [otherProvince.name]: "",
  [city.name]: "",
  [otherCity.name]: "",
  [typedisappeared.name]: "1",
  [phonenumber.name]: "",
  [imagedisappeared.name]: "",
};

export default initialValues;
