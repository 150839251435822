//@Form
import checkout from "./form";

const {
  formField: { comment, direction, province, otherProvince, city, otherCity },
} = checkout;

const initialValues = {
  [comment.name]: "",
  [direction.name]: "",
  [province.name]: "",
  [otherProvince.name]: "",
  [city.name]: "",
  [otherCity.name]: "",
};

export default initialValues;
