import React from "react";
// @mui-components
import { Box, Typography } from "@mui/material";
function Submitting({ msg }) {
  return (
    <Box
      sx={{
        height: "80vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4">{msg}...</Typography>
    </Box>
  );
}

export default Submitting;
