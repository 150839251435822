// @yup
import * as Yup from "yup";
// @form
import checkout from "./form";
// @reg-exp
import { PasswordRegExp } from "../../../../../utils/RegExp";

const {
  formField: { email, password },
} = checkout;

const validations = [
  Yup.object().shape({
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(8, password.minValue)
      .matches(PasswordRegExp, { message: password.invalidMsg }),
  }),
];

export default validations;
