/* eslint-disable react-hooks/exhaustive-deps */

// @react
import { useState, useEffect } from "react";
// @API
import { Auth } from "../../utils/fetcher-api";
// @react-router-dom
import { useNavigate } from "react-router-dom";
// @components
import Snackbar from "../../components/Snackbar";
import Submitting from "../../components/submitting";

function Logout() {
  // use-@react-router-dom
  const navigate = useNavigate();

  async function handleResponseErrorsForDeleteUser(response) {
    if (response.status) {
      if (response.code === 200) {
        setIsOpen(true);
        setTypeAlert("success");
        setMessage("تم تسجيل الخروج بنجاح !");
        await sleep(1000);
        navigate("/auth/sign-in");
        return true;
      }
    } else {
      if (response.code === 403) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("قم بتسجيل الدخول اولا !");
        await sleep(1000);
        navigate("/auth/sign-in");
      } else if (response.code === 500) {
        // Snackbar-Message
        setIsOpen(true);
        setTypeAlert("error");
        setMessage("حدث خطا..حاول مرة اخرى !");
      }
    }
    return false;
  }

  // Snackbar
  const [message, setMessage] = useState("msg Alert !");
  const [typeAlert, setTypeAlert] = useState("error");
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  const [isSubmitting, setIsSubmitting] = useState(true);

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const logoutUser = async () => {
    setIsSubmitting(true);

    try {
      const response = await Auth.logout();
      await sleep(1000);

      await handleResponseErrorsForDeleteUser(response);
    } catch (error) {
      setIsSubmitting(false);

      // Snackbar-Message
      setIsOpen(true);
      setTypeAlert("error");
      setMessage("خطا..حاول مرة اخرى !");
    }
  };

  useEffect(() => {
    logoutUser();
  }, []);

  useEffect(() => {
    document.title = "مختفون | تسجيل الخروج";
  });

  return (
    <>
      {isSubmitting && <Submitting msg="تسجيل الخروج" />}
      <Snackbar
        isOpen={isOpen}
        message={message}
        typeAlert={typeAlert}
        handleClose={handleClose}
      />
    </>
  );
}

export default Logout;
