// @crypto-js
import CryptoJS from "crypto-js";
// @js-cookie
import Cookies from "js-cookie";
// random encryption key
import { key } from "../utils/fetcher-api";

export default async function EncryptData(data, setToken) {
  // I-Crypt
  const encryptData = CryptoJS.AES.encrypt(data, key).toString();
  if (setToken) Cookies.set("token", encryptData);

  return encryptData;
}
