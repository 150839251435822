// @Css-File
import "./index.css";
// @MUI-Components
import { IconButton, Box } from "@mui/material";
// @MUI-Icons
import CopyrightIcon from "@mui/icons-material/Copyright";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// @Img
import QC from "../../img/qc.ico";

function Footer() {
  return (
    <Box
      container
      sx={{
        p: 2,
        backgroundColor: "#bef2ff",
        color: "#16a4c7",
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
        justifyContent: "space-around",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <span dir="rtl" style={{ alignItems: "center" }}>
            جميع الحقوق محفوظة <CopyrightIcon /> {new Date().getFullYear()}
          </span>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            target="_blank"
            href="https://web.facebook.com/profile.php?id=100086451583531"
          >
            <FacebookOutlinedIcon sx={{ color: "#3B579D" }} />
          </IconButton>
          <IconButton
            target="_blank"
            href="www.linkedin.com/company/مختفون-disappeared"
          >
            <LinkedInIcon sx={{ color: "#1983BC" }} />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton>
          <a
            href="https://www.linkedin.com/in/aboussabir-abdellah-605b74205/"
            // href="https://atlas-innova-tech.online"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ height: 30, width: 30 }}
              src={QC}
              alt="atlas-innova-tech"
            />
          </a>
        </IconButton>
        طور من طرف
      </Box>
    </Box>
  );
}

export default Footer;
