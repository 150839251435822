// @mui-components
import { Grid, Paper, Typography, IconButton } from "@mui/material";
// @MUI-Icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// @components
import DashUsersSkeleton from "./skeletons/dash-users-skeleton";
import NoItems from "./no-items";
// @img
import search from "../img/search.png";

function PaperUsers(props) {
  const { data, isSubmitting } = props;

  const handleDeleteUser = (value) => {
    props.deleteId(value);
  };

  return (
    <>
      {isSubmitting ? (
        <DashUsersSkeleton />
      ) : data?.length === 0 ? (
        <NoItems img={search} msg="لا يوجد اي مستخدم حاليا !" />
      ) : (
        data.map((user) => {
          return (
            <Paper
              key={user.id}
              elevation={3}
              sx={{
                m: 1,
              }}
            >
              <Grid
                container
                sx={{
                  p: 1,
                }}
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "center", md: "right" },
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                >
                  <IconButton
                    onClick={() => handleDeleteUser(user.id)}
                    aria-label="delete"
                  >
                    <HighlightOffIcon color="error" />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="h6"> {user.name}</Typography>
                </Grid>
              </Grid>
            </Paper>
          );
        })
      )}
    </>
  );
}

export default PaperUsers;
