/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */

// @react
import { useState, useEffect } from "react";
// @mui-components
import { Box, CssBaseline } from "@mui/material";
import PropTypes from "prop-types";
// sidenav-items
import { SidenavItems } from "../../utils/select-options";
// @componets
import DrawerSidenav from "./drawer-sidenav";
import AppBarSidenav from "./appBar-sidenav";
import MainSidenav from "./main-sidenav";
import Loading from "../loading";

function Sidenav() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeSidenaveItem, setActiveSidenaveItem] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleActiveSidenavChange = (value) => {
    setActiveSidenaveItem(value);
  };

  // Sleeper
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const loading = async (value) => {
    setIsLoading(true);
    await sleep(3000);
    setIsLoading(false);
  };

  useEffect(() => {
    loading();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box sx={{ display: "flex" }} dir="rtl">
          <CssBaseline />
          <AppBarSidenav
            toggle={handleDrawerToggle}
            activeSidenav={handleActiveSidenavChange}
          />

          <DrawerSidenav
            isMobileOpen={mobileOpen}
            toggle={handleDrawerToggle}
            activeSidenav={handleActiveSidenavChange}
            SidenavItems={SidenavItems}
            activeSidenaveItemD={activeSidenaveItem}
          />

          <MainSidenav
            activeSidenav={handleActiveSidenavChange}
            activeSidenaveItem={activeSidenaveItem}
          />
        </Box>
      )}
    </>
  );
}

Sidenav.propTypes = {
  window: PropTypes.func,
};

export default Sidenav;
